@import "../../../assets/css/style.scss";
@import "../../../assets/css/mixins/LoadingAnimated.scss";

$product-status: (
	"Available": (
		"bgcolor": map-get($theme-colors, "success"),
	),
	"LowOfStock": (
		"bgcolor": map-get($theme-colors, "warning"),
	),
	"OutOfStock": (
		"bgcolor": map-get($theme-colors, "danger"),
	),
);

.inventory-card {
	&-header {
		.card-title {
			margin: 0;
			font-size: 24px;
			color: map-get($theme-colors, "blueish-80");
			font-weight: 700;
			line-height: 33px;
		}
		.inventory-btn {
			margin-right: 16px;
			width: 190px;
			height: 40px;
			border: none;
			border-radius: 4px;
			outline: none;
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			i {
				font-size: 24px !important;
				margin-right: 8px;
			}
			&-import {
				background-color: map-get($theme-colors, "blue-10");
				color: map-get($theme-colors, "info");
			}
			&-filter {
				background-color: map-get($theme-colors, "grey-80");
				border: 1px solid map-get($theme-colors, "grey-80");
				color: map-get($theme-colors, "white");
			}
			&-clear {
				background-color: map-get($theme-colors, "grey-60");
				color: map-get($theme-colors, "white");
			}
			&-export {
				background-color: transparent;
				border: 1px solid map-get($theme-colors, "grey-70");
				color: map-get($theme-colors, "blueish-50");
				i {
					color: map-get($theme-colors, "blueish-20");
				}
				.fa-spinner {
					font-size: 14px !important;
				}
			}
		}
		.filter-block {
			width: 190px;
			&-label {
				font-weight: 700;
				font-size: 12px;
				line-height: 16px;
				color: map-get($theme-colors, "blueish-80");
				margin-bottom: 8px;
			}
			input,
			select {
				font-weight: 400 !important;
				font-size: 14px !important;
				line-height: 19px !important;
				color: map-get($theme-colors, "blueish-50") !important;
			}
		}
	}
	&-body {
		overflow: auto;
		div {
			overflow: visible !important;
		}
		.pagination-nav {
			width: 1110px;
			overflow-x: hidden !important;
		}
		.rdt_ExpanderRow div {
			overflow: visible !important;
		}
		.filtered-sku-info {
			margin: 16px 32px 0 32px;
			height: 40px;
			border: 2px solid map-get($theme-colors, "info");
			border-radius: 5px;
			color: map-get($theme-colors, "info");
			background-color: map-get($theme-colors, "blue-10");
			&-icon {
				font-size: 24px;
			}
			&-text {
				font-weight: 400;
				font-size: 14px;
				line-height: 19px;
			}
		}
		.inventory-emptyState {
			&-title {
				font-weight: 700;
				font-size: 18px;
				line-height: 25px;
				color: map-get($theme-colors, "grey-70");
			}
			&-text {
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				color: map-get($theme-colors, "grey-80");
			}
		}
		.product-table {
			&-title {
				font-weight: 700;
				font-size: 14px;
				line-height: 19px;
				text-transform: uppercase;
				color: map-get($theme-colors, "blueish-80");
			}
			&-sku {
				&-image {
					border: 1px solid map-get($theme-colors, "grey-20");
					height: 48px;
					width: 48px;
				}
				.sku-info {
					&-name {
						font-style: normal;
						font-weight: 600;
						font-size: 14px;
						line-height: 19px;
						color: map-get($theme-colors, "grey-70");
					}
					p {
						font-style: normal;
						font-weight: 400;
						font-size: 12px;
						line-height: 16px;
						color: map-get($theme-colors, "grey-70");
					}
				}
			}
			&-product {
				.expand-icon {
					color: map-get($theme-colors, "blueish-20");
					-moz-transition: all 0.2s ease-in-out;
					-webkit-transition: all 0.2s ease-in-out;
					transition: all 0.2s ease-in-out;
					-moz-user-select: none;
					-webkit-user-select: none;
					-ms-user-select: none;
					user-select: none;
					&.isExpanded {
						color: var(--secondary);
						-moz-transform: rotate(-90deg);
						-webkit-transform: rotate(-90deg);
						transform: rotate(-90deg);
					}
				}
				.product-image-block {
					section.badge-pill {
						left: 50%;
						bottom: 0;
						transform: translate(-50%, 45%);
						background-color: map-get($theme-colors, "grey-65");
						border: 1px solid map-get($theme-colors, "white");
						font-weight: 400;
						font-size: 12px;
						line-height: 16px;
						color: map-get($theme-colors, "white");
						white-space: nowrap;
					}
					img {
						border: 1px solid map-get($theme-colors, "grey-20");
						height: 64px;
						width: 64px;
						max-width: 64px !important;
					}
				}
				.product-info {
					&-name {
						font-weight: 700;
						font-size: 14px;
						line-height: 19px;
						color: map-get($theme-colors, "grey-80");
					}
					p {
						font-weight: 400;
						font-size: 12px;
						line-height: 16px;
						&.product-info-code {
							color: map-get($theme-colors, "grey-70");
						}
						&.product-info-ref {
							color: map-get($theme-colors, "grey-60");
						}
					}
				}
			}
			&-seller {
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				color: map-get($theme-colors, "grey-80");
				text-align: center;
			}
			&-lastUpdate {
				.lastUpdate-date {
					font-weight: 600;
					font-size: 14px;
					line-height: 19px;
					color: map-get($theme-colors, "grey-65");
				}
				.lastUpdate-hour {
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					color: map-get($theme-colors, "grey-60");
				}
			}
			&-inventory {
				background-color: map-get($theme-colors, "grey-60");
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
				color: map-get($theme-colors, "white");
				@each $status, $pairValue in $product-status {
					&.#{$status} {
						@if $status != "Available" {
							background-color: map-get($pairValue, "bgcolor");
						}
					}
				}
			}
			&-inventory-edit {
				margin: 0 5px;
				&.invalid-sku {
					.edit-sku {
						border: 2px solid map-get($theme-colors, "danger") !important;
					}
				}
				.edit-sku {
					height: 40px;
					outline: none;
					padding-left: 16px;
					caret-color: var(--secondary);
					background-color: map-get($theme-colors, "white");
					border: 2px solid var(--secondary);
					border-radius: 3px;
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					color: map-get($theme-colors, "blueish-50");
				}
				.edit-buttons {
					z-index: 10 !important;
					position: absolute;
					right: 0;
					top: calc(100% - 2px);
					button {
						outline: none;
						padding: 0;
						height: 40px;
						width: 40px;
						background-color: map-get($theme-colors, "grey-10");
						border: 2px solid map-get($theme-colors, "grey-20");
						cursor: pointer;
						&.edit-btn-confirm {
							border-radius: 0px 0px 0px 3px;
							border-right-width: 1px;
							color: map-get($theme-colors, "success");
						}
						&.edit-btn-cancel {
							border-radius: 0px 0px 3px 0px;
							border-left-width: 1px;
							color: map-get($theme-colors, "danger");
						}
						&:hover {
							background-color: map-get($theme-colors, "grey-20");
						}
						&:active {
							background-color: map-get($theme-colors, "grey-30");
						}
					}
				}
				i.LoadingAnimated {
					@include LoadingAnimated();
				}
			}
			&-status {
				&-product {
					i {
						margin: 3px;
						font-size: 18px;
						color: map-get($theme-colors, "white") !important;
					}
					@each $status, $pairValue in $product-status {
						&.#{$status} {
							background-color: map-get($pairValue, "bgcolor");
						}
					}
				}
				&-sku {
					height: 24px;
					font-weight: 400;
					font-size: 12px;
					line-height: 16px;
					color: map-get($theme-colors, "white");
					i {
						font-size: 16px;
					}
					@each $status, $pairValue in $product-status {
						&.#{$status} {
							@if $status == "Available" {
								color: map-get($theme-colors, "green-130");
								background-color: transparent;
								border: 1px solid map-get($pairValue, "bgcolor");
								padding: 4px 16px;
							} @else {
								background-color: map-get($pairValue, "bgcolor");
								padding: 4px 16px 4px 8px;
							}
						}
					}
				}
			}
			&-actions {
				height: 24px;
				button {
					background: none;
					border: none;
					padding: 0;
					margin: 0;
					font-size: 24px;
					outline: none;
					cursor: pointer;
					color: map-get($theme-colors, "blueish-20") !important;
					&:hover {
						color: var(--secondary) !important;
					}
					&:disabled {
						cursor: default;
						color: map-get($theme-colors, "grey-30") !important;
						&:hover {
							color: map-get($theme-colors, "grey-30") !important;
						}
					}
				}
			}
		}
	}
}
