.form-group {
	&.icon-right {
		i {
			position: absolute;
			top: 1.55em;
			right: 20px;
			color: map-get($theme-colors, "grey-50");
		}
		&-error {
			i {
				position: absolute;
				top: 1.55em;
				right: 23px;
				color: map-get($theme-colors, "danger");
			}
			&-select {
				i {
					position: absolute;
					top: 1.55em;
					right: 50px;
					color: map-get($theme-colors, "danger");
				}
			}
		}
	}
}

.form-control {
	height: 40px;
	border: 2px solid map-get($theme-colors, "grey-20");
	color: map-get($theme-colors, "blueish-80");
	font-size: 14px;
	&:active,
	&:focus {
		border-color: var(--secondary);
		box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
		color: map-get($theme-colors, "blueish-80");
	}
	&::placeholder {
		color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
	}
}

.disabled-dealer {
	&:disabled {
		color: map-get($theme-colors, "grey-60");
		background-color: map-get($theme-colors, "grey-10");
	}
}

.form-control-option {
	height: 22px; /* altura do select */
	line-height: 22px; /* este valor deve ser o mesmo acima */
	border: solid 1px red; /* cor da borda do select*/
	-webkit-tap-highlight-color: transparent;
	background-color: #fff;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	outline: none;
	padding-left: 18px;
	padding-right: 30px;
	position: relative;
	text-align: left !important;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	width: auto;
}
.form-control-option:hover {
	height: 40px;
	font-size: 14px;
	background: map-get($theme-colors, "blueish-20");
	color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
}

.input-group {
	.input-group-text {
		color: map-get($theme-colors, "blueish-80");
		background: map-get($theme-colors, "grey-20");
		border: 0;
	}
}

.custom-select {
	border: 2px solid map-get($theme-colors, "grey-20");
	height: 40px;
	&:focus {
		border: 2px solid var(--secondary);
		box-shadow: none;
	}
}

.custom-select-neo {
	width: 100%;
	height: 38px;
	padding: 5px 10px;
	border: 2px solid map-get($theme-colors, "grey-20");
	color: map-get($theme-colors, "blueish-20");
	border-radius: 4px;

	&:active,
	&:focus {
		border-color: var(--secondary);
		box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
		color: map-get($theme-colors, "blueish-20");
		outline: 0;
	}
	&::placeholder {
		color: rgba(map-get($theme-colors, "blueish-20"), 0.4);
	}
}

.custom-checkbox {
	cursor: pointer;
	.custom-control-label {
		&::before,
		&::after {
			cursor: pointer;
			top: 50%;
			width: 17px;
			height: 17px;
			transform: translateY(-50%);
		}
		&::before {
			border: 2px solid map-get($theme-colors, "grey-30");
			background-color: map-get($theme-colors, "white");
		}
	}
	.custom-control-input {
		&:checked ~ .custom-control-label::before {
			background: map-get($theme-colors, "info");
			border-color: map-get($theme-colors, "info");
		}
	}
}

.custom-radio-input {
	display: flex;
	flex-direction: row;
	margin-top: 10px;

	.radio-input {
		display: block;
		position: relative;
		padding-left: 26px;
		padding-right: 26px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 14px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		> input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 20px;
			width: 20px;
			border: solid 2px map-get($theme-colors, "grey-30");
			border-radius: 50%;
		}

		input:checked ~ .checkmark {
			background-color: map-get($theme-colors, "white");
			border: solid 3px map-get($theme-colors, "info");
		}

		.checkmark:after {
			content: "";
			position: absolute;
			display: none;
		}

		input:checked ~ .checkmark:after {
			display: block;
		}

		.checkmark:after {
			top: 3px;
			left: 3px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: map-get($theme-colors, "info");
		}
	}
}

.custom-radio-input:nth-child(-n + 5) {
	border-bottom: 1px solid #f5f5f5;
}

.autocomplete {
	position: relative;
	.search {
		width: 100%;
		&:focus {
			border-color: map-get($theme-colors, "grey-20");
			box-shadow: none;
		}
	}
	&.icon-right {
		i {
			position: absolute;
			top: 8px;
			right: 8px;
			color: map-get($theme-colors, "grey-50");
		}
	}
	.result {
		width: 100%;
		max-height: 400px;
		margin-top: -2px;
		overflow-y: scroll;
		position: absolute;
		background-color: map-get($theme-colors, "white");
		padding: 0;
		border: 2px solid map-get($theme-colors, "grey-20");
		border-radius: 0px 0px 5px 5px;
		z-index: 999;
		ul {
			list-style-type: none;
			padding: 0;
		}
		li {
			border-bottom: 1px solid map-get($theme-colors, "grey-20");
			padding: 10px;
			cursor: pointer;
		}
		li:last-child {
			border-bottom: none;
		}
	}
	.neo-loading {
		position: absolute;
		right: 10px;
		width: 30px;
		height: 30px;
	}
}

.rw-multiselect {
	padding: 0;
	height: auto;
	border: 0;

	&.rw-state-focus,
	&.rw-state-focus:hover {
		.rw-widget-container {
			box-shadow: none;
			border: 2px solid map-get($theme-colors, "grey-20");
		}
	}

	.rw-widget-input {
		box-shadow: none;
		border: 2px solid map-get($theme-colors, "grey-20");
	}
	.rw-widget-picker {
		position: relative;
		> * {
			display: flex;
			align-items: center;
		}
		.rw-select {
			position: absolute;
			right: 10px;
			top: 0;
		}
		.rw-multiselect-taglist {
			padding: 0.25rem;
			padding-bottom: 0;

			.rw-multiselect-tag {
				background: map-get($theme-colors, "grey-80");
				border: 0;
				color: map-get($theme-colors, "white");
				margin-top: 0;
				margin-left: 0;
				margin-right: 0.25rem;
				margin-bottom: 0.25rem;

				.rw-btn-select {
					opacity: 1;
				}
			}
		}
	}
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
	height: 300px !important;
}

.grouped-input-before {
	h6 {
		position: absolute;
		margin-top: 10px;
		margin-left: 10px;
		font-weight: 600;
		color: map-get($theme-colors, "grey-50");
		opacity: 0.8;
	}
	input {
		padding-left: 35px;
	}
}
.grouped-input-after {
	h6 {
		position: absolute;
		margin-top: -6%;
		right: 25%;
		font-weight: 600;
		color: map-get($theme-colors, "grey-50");
		opacity: 0.8;
	}
	input {
		padding-left: 35px;
	}
}
.section-step {
	margin-left: 20px;
	width: 100%;
	margin-bottom: 50px;
	.number {
		position: absolute;
		left: 20px;
		background: map-get($theme-colors, "grey-80");
		width: 30px;
		height: 30px;
		font-size: 18px;
		border-radius: 50%;
		text-align: center;
		color: map-get($theme-colors, "white");
	}
	h3 {
		margin-left: 20px;
		font-weight: bold;
		font-size: 20px;
		color: map-get($theme-colors, "blueish-80");
	}
}

.react-datepicker-popper {
	width: 330px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.react-select-container {
	> div {
		border-radius: 4px;
		border: 2px solid map-get($theme-colors, "grey-20");
		&:hover {
			border: 2px solid map-get($theme-colors, "grey-20");
		}
		&:focus-within {
			border: 2px solid var(--secondary);
			box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
			color: map-get($theme-colors, "blueish-80");
		}
		&:active {
			border: 2px solid var(--secondary);
			box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
			color: map-get($theme-colors, "blueish-80");
		}
	}
	div {
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 6px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: map-get($theme-colors, "bgNeo");
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: map-get($theme-colors, "grey-60");
		}
	}
	div {
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 6px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: map-get($theme-colors, "bgNeo");
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: map-get($theme-colors, "grey-60");
		}
	}
	.react-select-container-group {
		&-badge {
			background-color: map-get($theme-colors, "grey-20");
			border-radius: 2em;
			color: map-get($theme-colors, "blueish-80");
			display: inline-block;
			font-weight: normal;
			padding: 2px 8px;
			text-align: center;
		}
	}
}
.search-plus-button {
	height: 38px;
}

.group-control-price {
	display: flex;
	.label-control-prices {
		position: absolute;
		font-size: 14px;
		top: 44px;
		left: 10px;
		z-index: 1;
	}
	input {
		padding-left: 28px !important;
	}
	.form-control-prices {
		padding-left: 20px;
		width: 100%;
		border-radius: 5px !important;
		padding: 8px;
		border: 2px solid map-get($theme-colors, "grey-20");
		color: map-get($theme-colors, "blueish-80");
		font-size: 14px;
		&:focus {
			outline: none !important;
			border-color: var(--secondary);
			box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
		}
		&:active {
			border: 2px solid var(--secondary);
			box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
			color: map-get($theme-colors, "blueish-80");
		}
	}
}
.form-neo {
	width: 100%;
	display: flex;
	height: 40px;
	border: 2px solid map-get($theme-colors, "grey-20");
	border-radius: 5px;
	color: map-get($theme-colors, "blueish-80");

	> span {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2px 0 0 10px;
	}

	input:focus,
	button:focus {
		box-shadow: 0 0 0 0;
		outline: 0;
		border: none;
	}

	&.input-neo {
		display: flex;
		align-items: center;
		align-self: center;
		border: none;
		height: 35px;
		padding: 7px;
	}

	&:focus-within,
	&:active {
		border: 2px solid var(--secondary);
		border-radius: 3px;
		box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
		color: map-get($theme-colors, "blueish-80");
	}

	&.input-btn-icon {
		background: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		width: 40px;
		height: 40px;
		cursor: pointer;
		> i {
			margin-bottom: 5px;
			color: map-get($theme-colors, "blueish-20");
		}
	}

	//relacionadas ao <PhoneInput />
	.form-control {
		border: none;
		width: 100%;
	}
	//relacionadas ao <PhoneInput />
	.flag-dropdown {
		border: none;
	}
}

.form-neo-error {
	width: 100%;
	display: flex;
	height: 40px;
	border: 2px solid map-get($theme-colors, "grey-20");
	border-radius: 5px;
	color: map-get($theme-colors, "blueish-80");

	> span {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2px 0 0 10px;
	}

	input:focus,
	button:focus {
		box-shadow: 0 0 0 0;
		outline: 0;
		border: none;
	}

	&.input-neo {
		display: flex;
		align-items: center;
		align-self: center;
		border: none;
		height: 35px;
		padding: 7px;
	}

	&:focus-within,
	&:active {
		border: 2px solid map-get($theme-colors, "danger");
		border-radius: 3px;
		box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "danger"), 0.25);
		color: map-get($theme-colors, "blueish-80");
	}

	&.input-btn-icon-error {
		background: transparent;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		width: 40px;
		height: 40px;
		cursor: pointer;
		> i {
			margin-bottom: 5px;
			color: map-get($theme-colors, "danger");
		}
	}
}
