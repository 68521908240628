@import "../../../assets/css/style.scss";

.import-matcher {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	height: 80px;
	border-radius: 7px;
	border: 1px solid #dbdbdb;
	padding: 1.15em;
	align-items: center;
	&-icon {
		display: flex;
		min-height: 48px;
		min-width: 48px;
		background: map-get($theme-colors, "grey-80");
		border-radius: 48px;
		justify-content: center;
		align-items: center;
		.material-icons {
			height: 24px;
			color: #fff;
		}
	}
	&-description {
		line-height: 10px;
		margin-left: 16px;
		.link {
			color: map-get($theme-colors, "info") !important;
			text-decoration: none !important;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
		}
		p {
			display: flex;
			align-items: center;
			font-weight: bold;
			line-height: 19px;
			margin-bottom: 3px;
		}
	}
	&-upload {
		.btn {
			input[type="file"] {
				display: none;
			}
			width: 190px;
		}
		flex: 1;
		display: flex;
		justify-content: end;
	}
}

.import-status-titles {
	font-size: 18pxs;
}

@media screen and (max-width: 992px) {
	.mobile-space {
		margin-top: 1em;
	}
}

.tooltipNeoMatcher {
	cursor: help;
	display: flex;
	position: relative;
	// color: #ffffff !important;
	font-size: 12px;
	border-radius: 4px;
	font-weight: 500;
	// padding: 8px 10px 0 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;

	i {
		font-size: 16px;
	}

	> span {
		position: absolute;
		text-align: center;
		visibility: hidden;
		white-space: normal;
		right: 0;
		width: 360px;
		line-height: 16px;
		background-color: map-get($theme-colors, "black");
		color: map-get($theme-colors, "white") !important;
		border-radius: 5px;
		padding: 5px 10px;
	}

	span:after {
		content: "";
		position: absolute;
		top: 100%;
		right: 45%;
		margin-left: -8px;
		width: 0;
		height: 0;
		border-top: 8px solid map-get($theme-colors, "black");
		border-right: 8px solid map-get($theme-colors, "link");
		border-left: 8px solid map-get($theme-colors, "link");
	}
}
.tooltipNeoMatcher:hover span {
	visibility: visible;
	opacity: 0.9;
	bottom: 33px;
	right: 0;
	z-index: 999;
	right: -157px;
}
