@mixin TooltipAnimated($width, $tooltipAfterPosition) {
	position: relative;
	&::before,
	&::after {
		--scale: 0;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%) translateY(var(--translate-y, 0))
			scale(var(--scale));
		transition: 150ms transform;
		transform-origin: bottom center;
	}
	&::before {
		white-space: normal;
		--translate-y: calc(-100% - 8px);
		content: attr(data-tooltipcontent);
		width: $width;
		padding: 8px;
		border-radius: 4px;
		background-color: map-get($theme-colors, "grey-90");
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		color: map-get($theme-colors, "white");
	}
	&:hover::before,
	&:hover::after {
		--scale: 1;
	}
	&::after {
		--translate-y: calc(#{$tooltipAfterPosition} - 12px);
		transform-origin: top center;
		content: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.75858 8.75338C8.0021 10.15 5.99789 10.15 5.24142 8.75338L0.5 1.72333e-07L13.5 9.53674e-07L8.75858 8.75338Z' fill='%232E2E34'/%3E%3C/svg%3E%0A");
	}
}
