.baixe-modelo{
  text-align: right;
  width: 77%;
  margin: auto;
  margin-top: 10px;
  color:map-get($theme-colors, "grey-65");
  i{
    font-size: 12px;
  }
}
.splash-preview{
  width: 300px;
  height: 480px;
  margin: auto;
  background-color:map-get($theme-colors, "white");
}
.logo-splash{
  height: 480px;
  margin-left: 15px;
  width: 270px;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 10px;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
}
.screen-splash{
  height: 480px;
  width: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
}