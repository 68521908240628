@import "../../assets/css/style.scss";

.container-login {
	width: 100%;
	height: 100vh;
	background-color: map-get($theme-colors, "grey-90");
	background-position: right;
	background-repeat: no-repeat;
	transition: 2000ms ease;
	background-size: cover;

	.bg-login {
		margin: 0;
		padding: 0 116px;
		display: flex;
		align-items: center;
		z-index: 1000;
		width: 100%;
		height: 100vh;
		min-width: auto;
		min-height: 600px;
		background: linear-gradient(
			100deg,
			map-get($theme-colors, "grey-90") 50%,
			transparent calc(50% + 2px)
		);

		.new-login {
			background-color: map-get($theme-colors, "grey-90");
			padding: 24px;
			border-radius: 5px;
			margin-bottom: 50px;
			width: 450px;

			h2 {
				font-size: 24px;
				padding-bottom: 0.5rem;
				margin: 0;
				font-weight: 600;
				color: map-get($theme-colors, "white");
			}
		}
	}

	.initial {
		height: 450px;
		overflow: hidden;

		h2::before {
			display: block;
			width: 129px;
			border-bottom: 2px solid map-get($theme-colors, "white");
			content: "";
			margin: 30px 0 43px 0;
		}

		._link {
			padding-top: 8px;
			cursor: pointer;
			color: map-get($theme-colors, "white");
			font-size: 12px;
			font-weight: 400;
			margin: 0;
			text-decoration: underline;
		}

		._link:hover {
			color: darken(map-get($theme-colors, "white"), 20%);
			text-decoration: underline;
		}

		._error {
			padding: 8px;
			margin-bottom: 10px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: map-get($theme-colors, "danger");
		}
	}

	.roleContainer {
		text-align: left;
		max-height: 220px;
		padding: 0;
		border: 1px solid map-get($theme-colors, "grey-20");
		border-radius: 5px;

		.itemRole {
			list-style: none;
			position: relative;

			&:not(:first-child):not(:last-child) {
				border-top: 1px solid map-get($theme-colors, "grey-20");
			}
			&:last-child {
				border-top: 1px solid map-get($theme-colors, "grey-20");
			}
			.name {
				font-weight: 600;
			}
			.role {
				font-size: 12px;
				display: block;
			}
			.go {
				a {
					color: var(--secondary);
				}
			}
			img {
				width: 30px;
				height: 30px;
				border-radius: 15px;
			}
		}
	}
}
