@import "../../assets/css/style.scss";
@import "./style/OrderFlowStyle.scss";
@import "./style/InvoiceStyle.scss";
@import "./style/TrackingStyle.scss";
@import "./../../assets/css/mixins/TooltipAnimated.scss";

.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;

	.order-box {
		width: 100%;
		box-sizing: border-box;

		.order-main-box {
			margin: 0;
			padding: 24px 24px 40px 24px;
		}

		.order-header-box {
			margin: 0;
			padding: 0;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			align-self: flex-start;
			//background-color: aqua;

			> h2 {
				margin: 5px 0 0 0;
				padding: 0;
				display: flex;
				align-items: flex-start;
				align-self: flex-start;
				font-size: 18px;
				font-weight: 700;
				color: map-get($theme-colors, "blueish-80");
			}

			> span {
				color: map-get($theme-colors, "white");
				min-width: 53px;
				height: 24px;
				font-size: 12px;
				font-weight: 700;
				text-align: center;
				margin: 0;
				padding: 3px 8px 0 8px;
				border-radius: 5px;
			}

			.hyper-link {
				font-weight: 400;
				font-size: 14px;
				line-height: 19px;
				color: map-get($theme-colors, "info");
			}
			.hyper-link:hover {
				text-decoration-line: underline;
			}
		}

		.order-body-box {
			margin: 0;
			padding: 8px 0;
			display: flex;
			flex-direction: column;

			.text {
				&-blueish {
					color: map-get($theme-colors, "blueish-20");
					margin: 2px 0;
					font-size: 14px;
					font-weight: 500;
				}

				&-grey-60 {
					color: map-get($theme-colors, "grey-60");
					margin: 2px 0;
					font-size: 14px;
					font-weight: 500;
				}

				&-white {
					color: map-get($theme-colors, "white");
					margin: 2px 0;
					font-size: 14px;
					font-weight: 400;
				}

				&-blueish-md {
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					color: map-get($theme-colors, "blueish-20");
				}
			}

			.btn-create-invoice {
				cursor: pointer !important;
				width: 100%;
				height: 48px;
				margin-top: 10px;
				background: var(--primary);
				color: map-get($theme-colors, "white");
				display: flex;
				justify-items: center;
				justify-content: center;
				align-items: center;
				align-self: center;
				border: none;
				outline: none;
				border-radius: 4px;

				> i {
					margin-right: 10px;
				}
			}

			.double-cards-carousel {
				height: fit-content;
				width: 100%;
				border-radius: 8px;
				background-color: map-get($theme-colors, "grey-10");
				padding: 19px 24px 60px;
				margin-top: 16px;

				button {
					width: fit-content;
					height: fit-content;
					padding: 0;
					margin: auto;
					color: map-get($theme-colors, "blueish-20");
					background-color: transparent;
					opacity: 1;
					border: 0;
					cursor: pointer;
					&:disabled {
						color: map-get($theme-colors, "grey-30");
					}
				}

				.carousel-indicators {
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					width: 90%;
					margin: 0 auto;
					padding: 0;

					&-item {
						height: 4px;
						width: 24px;
						border-radius: 8px;
						background-color: map-get($theme-colors, "grey-50");
						transition: linear 0.2s;
						&.active {
							background-color: var(--secondary);
						}
					}
				}
				.carousel-inner {
					font-size: 14px;
					line-height: 19px;
					color: map-get($theme-colors, "blueish-80");
				}
				.carousel-item {
					margin: 0;
				}
			}
		}

		.box-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			margin-bottom: 15px;
			h2 {
				padding-top: 5px;
				font-weight: bold;
				font-size: 17px;
			}
			.success {
				margin-left: 8px;
				background: map-get($theme-colors, "success");
				color: map-get($theme-colors, "white");
				text-align: center;
				padding: 10px 10px;
				border-radius: 5px;
				font-weight: 500;
			}
			.warning {
				margin-left: 8px;
				background: map-get($theme-colors, "warning");
				color: map-get($theme-colors, "white");
				text-align: center;
				padding: 10px 10px;
				border-radius: 5px;
				font-weight: 500;
			}
			.danger {
				margin-left: 8px;
				background: map-get($theme-colors, "danger");
				color: map-get($theme-colors, "white");
				text-align: center;
				padding: 10px 10px;
				border-radius: 5px;
				font-weight: 500;
			}
			label {
				padding: 10px;
				border-radius: 5px;
				background: map-get($theme-colors, "bgNeo");
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				color: map-get($theme-colors, "blueish-80");
			}
		}

		.text-orders {
			margin: 0;
			&-title {
				font-weight: bolder;
				font-size: 16px;
			}
			&-carousel {
				font-size: 14px;
				line-height: 22px;
				color: map-get($theme-colors, "blueish-50");
			}
		}

		span {
			font-size: 14px;
			color: map-get($theme-colors, "blueish-80");
			margin-bottom: 8px;
		}
	}
}

.grid > div:nth-child(1) {
	background: map-get($theme-colors, "grey-90");
}

.grid > div.flow-status {
	grid-column: 1 / 4;
	grid-row: 2 / 5;
	display: block;
}

@media (max-width: 1200px) {
	.grid {
		grid-template-columns: repeat(2, 1fr);
	}

	.grid > div.flow-status {
		grid-column: 1 / 3;
		order: -1;
	}
}

@media (max-width: 770px) {
	.grid {
		grid-template-columns: repeat(1, 1fr);
	}

	.grid > div.flow-status {
		grid-column: 1 / 2;
		order: -1;
	}
}

$icons: (
	receipt_long: map-get($theme-colors, "grey-70"),
	attach_money: map-get($theme-colors, "grey-80"),
	receipt: map-get($theme-colors, "grey-80"),
	person_outline: map-get($theme-colors, "grey-80"),
	card_giftcard: map-get($theme-colors, "grey-80"),
	local_shipping: map-get($theme-colors, "grey-80"),
	shopping_bag: map-get($theme-colors, "grey-80"),
);

.icon-card-detail {
	position: absolute;
	top: -20px;
	left: 25px;
	background: map-get($theme-colors, "grey-70");
	width: 40px;
	height: 40px;
	border-radius: 50%;

	> i {
		display: flex;
		justify-content: center;
		text-align: center;
		margin-top: 7px;
		color: map-get($theme-colors, "white");
	}

	@each $name, $value in $icons {
		&.#{$name} {
			background: $value;
		}
	}
}

.scroll-list {
	height: 80%;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: map-get($theme-colors, "grey-60");
	}
}
