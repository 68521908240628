@import "../../../../assets/css/style.scss";

.date-filter-component {
	.date-filter {
		.date-filter-title {
			font-style: normal;
			font-weight: bold;
			font-size: 14px;
			color: map-get($theme-colors, "titles");
		}
		.group-btn {
			display: flex;
			flex-direction: row;

			> button {
				display: inline-block;
				cursor: pointer;
				box-shadow: 0 0 0 0;
				outline: 0;
				background: transparent;
				height: 40px;
				padding: 0 10px;
				border: 2px solid map-get($theme-colors, "grey-20");
				color: #c4c4c4;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;

				& + button {
					border-left: none;
					flex: 1;
				}
				&:first-child {
					border-radius: 5px 0 0 5px;
				}
				&:last-child {
					border-radius: 0 5px 5px 0;
				}
			}
			.dropdown {
				display: inline-block;
				cursor: pointer;
				box-shadow: 0 0 0 0;
				outline: 0;
				background: transparent;
				height: 40px;
				padding: 0 0px;
				border: 2px solid map-get($theme-colors, "grey-20");
				border-left: none;
				color: #c4c4c4;
				font-size: 11px;
				font-weight: 500;
				text-transform: uppercase;
				border-radius: 0 5px 5px 0;

				& + button {
					border-left: none;
					flex: 1;
				}
				.dropdown-toggle {
					background-color: transparent;
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					.material-icons {
						font-size: 16px;
					}
					span {
						justify-self: center;
					}
				}
			}
			.active-data-btn {
				background-color: map-get($theme-colors, "grey-80");
				color: #fff;
				border: none;
				height: 40px;
				> .calendar-toggle {
					&:active {
						background-color: map-get($theme-colors, "grey-80");
						color: #fff;
						border: none;
						margin: none;
						height: 37px;
						border-radius: 0 5px 5px 0 !important;
					}
				}
			}
		}
	}
}
