.detail {
	margin-top: 8px;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid map-get($theme-colors, "grey-30");

	.detail-tabs {
		button {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			text-align: center;
			color: map-get($theme-colors, "blueish-80");
			width: auto;
			border: none;
			padding: 15px 2.5em 15px 2.5em;
			background: map-get($theme-colors, "link");
			cursor: pointer;
		}
		button:focus {
			outline: 0px auto -webkit-focus-ring-color;
			outline-offset: 0px;
		}
		.actived {
			border-bottom: 2px solid map-get($theme-colors, "grey-80");
			color: map-get($theme-colors, "grey-80");
			font-weight: 700;
		}
		.loading {
			cursor: wait;
		}
	}

	.detail-invoice {
		margin-right: 6px;
		display: flex;
		align-items: center;

		.loading-invoice {
			cursor: wait;
		}

		> button {
			display: flex;
			justify-content: center;
			align-content: center;
			padding: 8px;
			box-shadow: 0 0 0 0;
			outline: 0;
			border: none;
			background: map-get($theme-colors, "grey-30");
			border-radius: 5px;
			cursor: pointer;

			> i {
				color: map-get($theme-colors, "grey-65");
				font-size: 24px;
			}
		}
	}
}

.detail-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
	.box-only {
		width: 32.6%;
		box-sizing: border-box;
		padding: 25px 20px;

		.box-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			margin-bottom: 15px;
			h2 {
				padding-top: 5px;
				font-weight: bold;
				font-size: 17px;
			}
			label {
				padding: 10px;
				border-radius: 5px;
				background: map-get($theme-colors, "bgNeo");
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				color: map-get($theme-colors, "blueish-80");
			}
		}

		span {
			font-size: 14px;
			color: map-get($theme-colors, "blueish-80");
			margin-bottom: 8px;
		}
	}

	.box-one {
		width: 100%;
		box-sizing: border-box;
		padding: 25px 20px;
		.create-tracking {
			height: 48px;
			background-color: transparent;
			border-radius: 4px;
			border: solid 1px map-get($theme-colors, "grey-80");
			color: map-get($theme-colors, "grey-80");
			cursor: pointer;
		}
		.create-tracking:focus {
			box-shadow: 0 0 0 0;
			outline: 0;
		}
		.create-tracking:hover {
			background-color: map-get($theme-colors, "grey-80");
			border: solid 1px map-get($theme-colors, "grey-80");
			color: map-get($theme-colors, "white");
		}

		.editing-tracking {
			height: 48px;
			background-color: transparent;
			border-radius: 4px;
			border: solid 1px map-get($theme-colors, "grey-60");
			color: map-get($theme-colors, "grey-60");
			cursor: pointer;
		}

		.editing-tracking:focus {
			box-shadow: 0 0 0 0;
			outline: 0;
		}
		.editing-tracking:hover {
			background-color: map-get($theme-colors, "grey-30");
			border: solid 1px map-get($theme-colors, "grey-30");
		}

		.tracking-box {
			display: flex;
			flex-direction: row;
			a {
				color: map-get($theme-colors, "info");
				font-size: 14px;
				font-weight: 500;
				font-style: italic;
				margin-left: 8px;
			}
			input {
				background: map-get($theme-colors, "light");
				border: none;
				font-size: 14px;
				position: absolute;
				top: 900px;
			}

			button {
				border: none;
				background: transparent;
				cursor: pointer;

				i {
					color: map-get($theme-colors, "grey-60");
					font-size: 18px;
					font-weight: 500;
				}
			}

			button:focus {
				box-shadow: 0 0 0 0;
				outline: 0;
			}

			input:focus {
				box-shadow: 0 0 0 0;
				outline: 0;
			}
		}

		.box-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;
			margin-bottom: 15px;
			h2 {
				padding-top: 5px;
				font-weight: bold;
				font-size: 17px;
			}
			.success {
				margin-left: 8px;
				background: map-get($theme-colors, "success");
				color: map-get($theme-colors, "white");
				text-align: center;
				padding: 10px 10px;
				border-radius: 5px;
				font-weight: 500;
			}
			.warning {
				margin-left: 8px;
				background: map-get($theme-colors, "warning");
				color: map-get($theme-colors, "white");
				text-align: center;
				padding: 10px 10px;
				border-radius: 5px;
				font-weight: 500;
			}
			.danger {
				margin-left: 8px;
				background: map-get($theme-colors, "danger");
				color: map-get($theme-colors, "white");
				text-align: center;
				padding: 10px 10px;
				border-radius: 5px;
				font-weight: 500;
			}
			label {
				padding: 10px;
				border-radius: 5px;
				background: map-get($theme-colors, "bgNeo");
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				color: map-get($theme-colors, "blueish-80");
			}
		}

		.text-orders {
			margin: 0;
			&-title {
				font-weight: bolder;
				font-size: 16px;
			}
		}

		span {
			font-size: 14px;
			color: map-get($theme-colors, "blueish-80");
			margin-bottom: 8px;
		}
	}

	div + div {
		margin-left: 15px;
	}

	.box-two {
		width: 68%;
		box-sizing: border-box;
		padding: 25px 20px;
		display: flex;
		flex: 1;
		.status {
			display: flex;
			justify-content: space-between;

			label {
				padding: 10px;
				border-radius: 5px;
				font-weight: 500;
			}
		}
	}

	.box-three {
		width: 100%;
		padding: 25px 20px;
		.box-container-orders {
			display: flex;
			.box-header {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				width: 40%;
				.box-img-orders {
					margin-top: 20px;
					margin-left: 7px;
					margin-bottom: 5px;
					max-width: 50px;
					max-height: 300px;
					> img {
						width: 100px;
						max-width: 300px;
						max-height: 300px;
					}
				}
			}
			.box-body {
				width: 100%;
				display: flex;
				flex-direction: row;

				> div {
					display: flex;
					flex-direction: column;
					margin: 20px;
					padding: 10px 30px;
					color: map-get($theme-colors, "blueish-20");
					font-size: 14px;
					line-height: 16px;
				}
			}
		}
		.box-table {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.total {
				width: 35%;
				padding-right: 30px;
				padding-bottom: 20px;
				border-bottom: 1px dashed #e9ecec;
				display: flex;
				justify-content: flex-end;

				> div {
					display: flex;
					flex-direction: column;

					.title-1 {
						padding-right: 100px;
						color: map-get($theme-colors, "blueish-20");
					}
					.title-2 {
						display: flex;
						justify-content: flex-end;
					}
				}
			}
		}
	}
}

.payment-carousel {
	height: fit-content;
	width: 100%;
	border-radius: 8px;
	background-color: map-get($theme-colors, "grey-10");
	padding: 19px 40px 44px;

	button {
		width: fit-content;
		height: fit-content;
		padding: 0;
		margin: auto;
		color: map-get($theme-colors, "blueish-20");
		background-color: transparent;
		// opacity: .5;
		opacity: 1;
		border: 0;
		cursor: pointer;
		&:disabled {
			color: map-get($theme-colors, "grey-30");
		}
		&:hover {
			color: map-get($theme-colors, "blueish-20");
		}
	}

	.carousel-indicators {
		margin-bottom: 8px;
		&-item {
			height: 4px;
			width: 24px;
			border-radius: 8px;
			background-color: map-get($theme-colors, "grey-50");
			transition: linear 0.2s;
			&.active {
				background-color: var(--secondary);
			}
		}
	}
	.carousel-inner {
		font-size: 14px;
		line-height: 19px;
		color: map-get($theme-colors, "blueish-80");
	}
	.carousel-item {
		margin: 0;
	}
}

/* area do historico */
.card-historic-description {
	padding: 10px 20px;
	color: map-get($theme-colors, "grey-30");
	font-size: 12px;
	font-weight: 400;
}

.card-historic {
	border: 1px solid map-get($theme-colors, "grey-10");
	border-radius: 5px;

	.card-historic-title {
		padding: 8px 20px;
		color: map-get($theme-colors, "blueish-20");
		font-size: 14px;
		font-weight: bold;
	}
}

.order-label {
	text-transform: uppercase;
	height: 80px;
	> h2 {
		margin: 0;
		label {
			margin: 0;
		}
	}
	> span {
		color: map-get($theme-colors, "blueish-80");
		font-size: 12px;
	}
}

@media only screen and (max-width: 1200px) {
	/* .detail-container .box-two .status label {
        padding: 3px 0;
    } */

	.order-label {
		> span {
			margin-top: 3px;
			margin-bottom: 5px;
		}
	}
}

@media only screen and (max-width: 990px) {
	.detail-container {
		flex-direction: column;
		.box-only {
			width: 100%;
			margin-bottom: 15px;
		}
		.box-one {
			width: 100%;
			margin: 0;
			margin-bottom: 15px;
		}
		div + div {
			margin-bottom: 15px;
		}

		.box-two {
			width: 100%;
			margin: 0;
		}

		.box-three {
			width: 100%;
			margin-top: -10px;
			.box-container-orders {
				display: flex;
				flex-direction: column;
				.box-header {
					width: 100%;
				}
				.box-body {
					width: 100%;
					display: flex;
					flex-direction: column;
					margin-left: 0;
					> div {
						display: flex;
						flex-direction: column;
						margin: 5px 20px;
						padding: 0;
						color: map-get($theme-colors, "blueish-20");
						font-size: 14px;
						line-height: 16px;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.order-label {
		> span {
			margin-top: 5px;
			margin-bottom: 0;
		}
	}
}

.download-pdf-xml {
	background: #fafafa;
	border: 3px dashed #e0e3e7;
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	align-content: center;
	cursor: pointer;
	height: 96px;

	.success {
		display: flex;
		justify-content: center;
		align-self: center;
		padding: 0 20px;
		margin: 0 auto;
		> div {
			i {
				cursor: pointer;
				font-size: 25px;
				color: map-get($theme-colors, "blueish-20");
				padding: 7px 0;
			}
			span {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 700;
				color: map-get($theme-colors, "blueish-20");
			}
		}
		.success-icon {
			color: #98ff98;
			font-size: 40px;
		}
	}
}
.actived-download {
	border: 3px dashed var(--secondary);
	background: #fafafa;
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	align-content: center;
	cursor: pointer;
	height: 96px;
	.success {
		display: flex;
		justify-content: center;
		align-self: center;
		padding: 0 20px;
		margin: 0 auto;
		> div {
			i {
				cursor: pointer;
				font-size: 25px;
				color: map-get($theme-colors, "blueish-20");
				padding: 7px 0;
			}
			span {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 700;
				color: map-get($theme-colors, "blueish-20");
			}
		}
	}
}

// A classe disabled-download não está sendo utilizada, revisar futuramente!
.disabled-download {
	border: 3px dashed map-get($theme-colors, "danger");
	background: #fafafa;
	box-sizing: border-box;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	align-content: center;
	cursor: pointer;
	height: 96px;
	.success {
		display: flex;
		justify-content: center;
		align-self: center;
		padding: 0 20px;
		margin: 0 auto;
		> div {
			i {
				cursor: pointer;
				font-size: 25px;
				color: map-get($theme-colors, "blueish-20");
				padding: 7px 0;
			}
			span {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 700;
				color: map-get($theme-colors, "blueish-20");
			}
		}
	}
}

.download-group {
	border: 2px solid map-get($theme-colors, "grey-10");
	height: 40px;
	display: flex;
	justify-content: space-between;

	.download-group-label {
		padding: 0 10px;
		display: flex;
		align-items: center;
		align-self: center;
		align-content: center;

		> i {
			padding-right: 10px;
			color: map-get($theme-colors, "blueish-20");
		}
	}

	.download-group-icons {
		padding: 0 10px;
		display: flex;
		align-items: center;
		align-self: center;
		align-content: center;

		> button {
			cursor: pointer;
			padding-top: 10px;
			background: map-get($theme-colors, "link");
			border: none;
			outline: none;
		}
		> a {
			cursor: pointer;
			padding-top: 10px;
			color: map-get($theme-colors, "blueish-20");
		}
	}
}

// A classe disabled-download não está sendo utilizada, revisar futuramente!
.date-orders {
	width: 215px;
	background: map-get($theme-colors, "grey-50");
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center;
	align-content: center;
	border-radius: 4px;

	i {
		color: map-get($theme-colors, "blueish-80");
		padding: 8px 9px;
		font-size: 20px;
	}
}

// A classe disabled-download não está sendo utilizada, revisar futuramente!
.status-select,
.status-select:focus {
	width: 200px;
	padding: 12px;
	border-radius: 5px;
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	box-shadow: 0 0 0 0;
	border: 0 none;
	outline: 0;

	option,
	option:focus {
		padding: 10px 50px;
		border-radius: 5px;
		border: none;
		color: #ffffff;
		font-size: 18px;
		font-weight: 500;
		box-shadow: 0 0 0 0;
		border: 0 none;
		outline: 0;
	}
}

// Utilizado no progress orders!
.separete {
	background-color: map-get($theme-colors, "blueish-80");
	color: map-get($theme-colors, "white");
}
.separete:hover {
	background-color: map-get($theme-colors, "blueish-80") !important;
}
.separete:active {
	background-color: map-get($theme-colors, "blueish-80") !important;
}
.separete:focus {
	background-color: map-get($theme-colors, "blueish-80") !important;
}

.counter-price {
	display: flex;
	justify-content: center;
	align-items: center;

	> button {
		color: map-get($theme-colors, "blueish-80");
		background-color: map-get($theme-colors, "white");
		border: none;
		cursor: pointer;
		text-align: center;
		outline: thin dotted;
		outline: 0px auto -webkit-focus-ring-color;
		outline-offset: 0px;
	}

	> input {
		width: 50px;
		height: 30px;
		border: none;
		padding: 5px;
		text-align: center;
		outline: thin dotted;
		outline: 0px auto -webkit-focus-ring-color;
		outline-offset: 0px;
	}
}

.edit-price {
	max-width: 150px;
	.value-price {
		display: flex;
		padding: 0;
		margin: auto 0;
		> label {
			position: relative;
			top: 6px;
			left: 25px;
		}
		> input {
			padding: 5px 0 5px 27px;
			border: 1px solid var(--secondary);
			box-sizing: border-box;
			border-radius: 5px !important;
		}
		> input:focus {
			outline: thin dotted;
			outline: 0px auto -webkit-focus-ring-color;
			outline-offset: 0px;
		}
	}

	.btn-price {
		display: flex;
		justify-content: flex-end;
		position: relative;

		> div {
			position: absolute;
			display: flex;
			> button {
				padding: 0;
				width: 28px;
				height: 28px;
				display: flex;
				justify-content: center;
				align-items: center;
				> i {
					font-size: 20px;
				}
			}
		}
	}
}

.btn-sku-default {
	background: map-get($theme-colors, "bgNeo");
	border: solid 3px map-get($theme-colors, "bgNeo");
	margin-right: 5px;
	font-weight: 400;
	width: 64px;
	height: 40px;
	border-radius: 3px;
	cursor: pointer;
}

.btn-sku-default:hover {
	background: var(--primary);
	border: solid 3px var(--primary);
	color: #ffffff;
	font-weight: 500;
}

.btn-sku-default:focus {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
}

.btn-sku-active {
	background: var(--primary);
	border: solid 3px var(--primary);
	color: #ffffff;
	margin-right: 5px;
	font-weight: 400;
	width: 64px;
	height: 40px;
	border-radius: 3px;
	cursor: pointer;
}

.btn-sku-active:focus {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
}

.btn-color-default {
	background: map-get($theme-colors, "bgNeo");
	border: solid 3px map-get($theme-colors, "bgNeo");
	font-weight: 400;
	width: 40px;
	height: 40px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	> button {
		border-radius: 3px;
		border: none;
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
	> button:focus {
		outline: thin dotted;
		outline: 0px auto -webkit-focus-ring-color;
		outline-offset: 0px;
	}
}
.btn-color-default:hover {
	border: solid 3px var(--primary);
	border-radius: 3px;
}
.btn-color-active {
	background: map-get($theme-colors, "bgNeo");
	border: solid 3px var(--primary);
	border-radius: 3px;
	font-weight: 400;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	> button {
		border-radius: 3px;
		border: none;
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
	> button:focus {
		outline: thin dotted;
		outline: 0px auto -webkit-focus-ring-color;
		outline-offset: 0px;
	}
}

.tooltipOrders {
	cursor: help;
	display: block;
	position: relative;
	color: #ffffff !important;
	font-size: 15px;
	width: 150px;
	height: 40px;
	text-transform: uppercase;
	border-radius: 4px;
	font-weight: 500;
	padding: 8px 10px 0 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;

	> span {
		position: absolute;
		text-align: center;
		visibility: hidden;
		right: 0;
		background-color: map-get($theme-colors, "black");
		color: map-get($theme-colors, "white") !important;
		border-radius: 5px;
		padding: 5px 10px;
	}

	span:after {
		content: "";
		position: absolute;
		top: 100%;
		right: 10%;
		margin-left: -8px;
		width: 0;
		height: 0;
		border-top: 8px solid map-get($theme-colors, "black");
		border-right: 8px solid map-get($theme-colors, "link");
		border-left: 8px solid map-get($theme-colors, "link");
	}
}
.tooltipOrders:hover span {
	visibility: visible;
	opacity: 0.9;
	bottom: 40px;
	right: 0;
	z-index: 999;
}

.filter-order {
	select {
		width: 300px;
	}
}

@media (max-width: 992px) {
	.filter-order {
		select {
			width: 100%;
		}
	}
}
