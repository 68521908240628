@import "../../assets/css/style.scss";

@media (max-width: 1199px) {
	.scroll-card {
		overflow-x: auto;
		scrollbar-width: thin;
		padding: 10px 0;

		&::-webkit-scrollbar {
			height: 8px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-track {
			border-radius: 40px;
			background-color: map-get($theme-colors, "grey-20");
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 40px;
			background-color: map-get($theme-colors, "grey-50");
		}
	}
}

@mixin status($background, $border, $cursor) {
	background: $background;
	border: $border;
	cursor: $cursor;
	-webkit-transition: background-color, 0.5s ease-out;
	-moz-transition: background-color, 0.5s ease-out;
	-o-transition: background-color, 0.5s ease-out;
	transition: background-color, 0.5s ease-out;
	-webkit-transition: border, 0.5s ease-out;
	-moz-transition: border, 0.5s ease-out;
	-o-transition: border, 0.5s ease-out;
	transition: border, 0.5s ease-out;
}

.btn-status {
	display: flex;
	align-items: center;
	margin: 0;
	padding: 16px 8px;
	width: 155px;
	height: 64px;
	border-radius: 8px;

	&.empty {
		@include status(
			map-get($theme-colors, "white"),
			1px dashed map-get($theme-colors, "grey-30"),
			auto
		);
	}

	&.success {
		@include status(
			map-get($theme-colors, "green-130"),
			1px solid map-get($theme-colors, "green-130"),
			pointer
		);
	}

	&.success-outline {
		@include status(
			map-get($theme-colors, "white"),
			2px solid map-get($theme-colors, "success"),
			auto
		);
	}

	&.warning {
		@include status(
			map-get($theme-colors, "warning"),
			1px solid map-get($theme-colors, "warning"),
			pointer
		);
	}

	&.warning-outline {
		@include status(
			map-get($theme-colors, "white"),
			2px solid map-get($theme-colors, "warning"),
			auto
		);
	}

	&.retry-outline {
		@include status(
			map-get($theme-colors, "white"),
			2px solid map-get($theme-colors, "info"),
			auto
		);
	}

	&.danger {
		@include status(
			map-get($theme-colors, "danger"),
			1px solid map-get($theme-colors, "danger"),
			pointer
		);
	}

	&.danger-outline {
		@include status(
			map-get($theme-colors, "white"),
			2px solid map-get($theme-colors, "danger"),
			auto
		);
	}

	&.completed,
	&.canceled {
		@include status(
			map-get($theme-colors, "grey-10"),
			1px solid map-get($theme-colors, "grey-50"),
			auto
		);
	}

	&:focus {
		outline: 0;
		box-shadow: none;
	}
}

.btn-status {
	&.success {
		&:hover {
			background: darken(map-get($theme-colors, "green-130"), 10%);
			border: 1px solid darken(map-get($theme-colors, "green-130"), 10%);
			//transform: translate(1px) scale(1.1);
		}
	}
	&.warning {
		&:hover {
			background: darken(map-get($theme-colors, "warning"), 10%);
		}
	}
	&.danger {
		&:hover {
			background: darken(map-get($theme-colors, "danger"), 20%);
			border: 1px solid darken(map-get($theme-colors, "danger"), 20%);
		}
	}
}

@mixin icon($background, $color) {
	background: $background;
	color: $color;
}

.icon-status {
	min-width: 30px;
	min-height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;

	&.empty {
		@include icon(
			map-get($theme-colors, "grey-65"),
			map-get($theme-colors, "white")
		);
	}

	&.success {
		@include icon(
			map-get($theme-colors, "white"),
			map-get($theme-colors, "green-130")
		);
	}

	&.success-outline,
	&.completed {
		@include icon(
			map-get($theme-colors, "success"),
			map-get($theme-colors, "white")
		);
	}

	&.warning {
		@include icon(
			map-get($theme-colors, "white"),
			map-get($theme-colors, "warning")
		);
	}

	&.warning-outline {
		@include icon(
			map-get($theme-colors, "warning"),
			map-get($theme-colors, "white")
		);
	}

	&.retry-outline {
		@include icon(
			map-get($theme-colors, "info"),
			map-get($theme-colors, "white")
		);
	}

	&.danger {
		@include icon(
			map-get($theme-colors, "white"),
			map-get($theme-colors, "danger")
		);
	}

	&.danger-outline,
	&.canceled {
		@include icon(
			map-get($theme-colors, "danger"),
			map-get($theme-colors, "white")
		);
	}

	> i {
		display: block;
		font-size: 20px !important;
	}
}

@mixin text($color) {
	color: $color;
}

.text-status {
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
	width: 100px !important;

	&.empty {
		@include text(map-get($theme-colors, "grey-60"));
	}
	&.success-outline,
	&.warning-outline,
	&.danger-outline,
	&.completed,
	&.canceled {
		@include text(map-get($theme-colors, "grey-90"));
	}

	&.success,
	&.warning,
	&.danger {
		@include text(map-get($theme-colors, "white"));
	}
}

.text-information {
	font-size: 10px;
	font-weight: 400;
	line-height: 16px;
	max-width: 115px;
	font-style: italic;

	&.empty {
		@include text(map-get($theme-colors, "grey-60"));
	}
	&.success-outline,
	&.warning-outline,
	&.danger-outline,
	&.completed,
	&.canceled {
		@include text(map-get($theme-colors, "grey-80"));
	}

	&.success,
	&.warning,
	&.danger {
		@include text(map-get($theme-colors, "white"));
	}
}

.count-input {
	display: flex;
	flex-direction: row;

	> span {
		display: flex;
		align-items: center;
		margin-left: -55px;
		margin-top: 8px;
		color: map-get($theme-colors, "grey-50") !important;
	}
}

.btn-tooltip {
	cursor: help;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.msg-tooltip {
	position: absolute;
	text-align: center;
	visibility: hidden;
	background-color: map-get($theme-colors, "black");
	color: map-get($theme-colors, "white") !important;
}

.msg-tooltip:after {
	content: "";
	position: absolute;
	top: 100%;
	right: 45%;
	border-top: 8px solid map-get($theme-colors, "black");
	border-right: 8px solid map-get($theme-colors, "link");
	border-left: 8px solid map-get($theme-colors, "link");
}

.btn-tooltip:hover .msg-tooltip {
	position: absolute;
	visibility: visible;
	width: 225px;
	border-radius: 5px;
	padding: 5px;
	white-space: normal;
	opacity: 0.9;
	bottom: 50px;
	z-index: 200;
}

.arrow-svg {
	min-height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;

	.img-svg {
		position: absolute;
		overflow: visible;
		z-index: 0;
		&.arrow-up {
			height: 205px;
			width: 30px;
			left: 169px;
			top: 28px;
		}
		&.arrow-straight {
			width: 381px;
			right: 172px;
			top: 227px;
		}
		&.arrow-straight-sm {
			left: -6px;
			bottom: 58px;
		}
		&.arrow-straight-lg {
			width: 465px;
			left: 89px;
			bottom: 60px;
		}
		&.arrow-down-right-sm {
			width: 102px;
			left: 90px;
			bottom: 59px;
		}
		&.arrow-down-right-md {
			width: 284px;
			left: 90px;
			bottom: 59px;
		}
		&.arrow-up-right {
			height: 290px;
			width: 100px;
			left: 89px;
			bottom: 47px;
		}
	}
}
