/* Metrcis */
.metrics-orders {
	select {
		width: 160px;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 14px;
		color: map-get($theme-colors, "blueish-80");
	}

	> p {
		color: map-get($theme-colors, "grey-30");
		font-size: 14px;
		font-weight: 500;
		margin-top: 5px;
		padding: 0 10px;
	}
}
.metrics {
	width: 130px;
	.metrics-title {
		color: map-get($theme-colors, "grey-30");
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
	}

	.metrics-value {
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 28px;
		color: map-get($theme-colors, "grey-30");
	}

	.metrics-icons-a {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: map-get($theme-colors, "success");
		display: flex;
		justify-content: start;
		align-items: center;
		align-content: center;
	}
	.metrics-icons-b {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		color: map-get($theme-colors, "danger");
		display: flex;
		justify-content: start;
		align-items: center;
		align-content: center;
	}
}

.filter-order-spdp {
	display: flex;
	justify-content: flex-start;
	margin-bottom: 10px;
	> div {
		width: 350px;
	}
}

.filter-search-spdp {
	width: 522px;
}

.btn-filtered {
	background-color: map-get($theme-colors, "grey-80");
	color: map-get($theme-colors, "white");
	border: none;
	border-radius: 4px;
	height: 26px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	padding: 2px 5px;
	margin-right: 8px;
	> i {
		font-size: 18px;
		margin-left: 2px;
	}
}

.btn-filtered:focus {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
}

@media (max-width: 892px) {
	.filter-order-spdp {
		> div {
			width: 100%;
		}
	}

	.filter-search-spdp {
		margin-top: 10px;
		width: 100%;
	}
}

.filter-orders {
	.filter-date {
		.group-btn {
			display: flex;
			flex-direction: row;

			> button {
				display: inline-block;
				cursor: pointer;
				box-shadow: 0 0 0 0;
				outline: 0;
				background: transparent;
				height: 40px;
				padding: 0 10px;
				border: 2px solid map-get($theme-colors, "grey-20");
				border-right: 0px;
				color: #c4c4c4;
				font-size: 12px;
				font-weight: 500;
				text-transform: uppercase;
				border-radius: 0 !important;

				& + button {
					flex: 1;
				}
				&:first-child {
					border-radius: 5px 0 0 5px !important;
				}
			}
			.dropdown {
				display: inline-block;
				cursor: pointer;
				box-shadow: 0 0 0 0;
				outline: 0;
				background: transparent;
				height: 40px;
				padding: 0 0px;
				border: 2px solid map-get($theme-colors, "grey-20");
				color: #c4c4c4;
				font-size: 11px;
				font-weight: 500;
				text-transform: uppercase;
				border-radius: 0 5px 5px 0;

				& + button {
					border-left: none;
					flex: 1;
				}
				.dropdown-toggle {
					background-color: transparent;
					font-size: 12px;
					font-weight: 500;
					text-transform: uppercase;
					.material-icons {
						font-size: 16px;
					}
					span {
						justify-self: center;
					}
				}
			}
			.active-data-btn {
				background-color: map-get($theme-colors, "grey-80");
				color: #ffffff;
				border: none;
				height: 40px;
				&:disabled {
					color: rgba(255, 255, 255, 0.7);
				}
				> .calendar-toggle {
					&:active {
						background-color: map-get($theme-colors, "grey-80");
						border: none;
						margin: none;
						height: 40px;
						border-radius: 0 5px 5px 0 !important;
					}
				}
				& + button,
				& + .dropdown {
					border-left: none;
				}
			}
		}
	}

	.MuiInputBase-root.MuiOutlinedInput-root {
		height: 40px;
	}
}

.loading-multidate {
	display: flex;
	justify-content: center;
	gap: 1em;
	align-items: center;
	width: 167px;
	height: 38px;
	background: transparent;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0) !important;
	border-bottom: 0px;
	border: none !important;
}

.invoice-orders {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 25px;
}
