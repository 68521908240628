@mixin bg-icon($background) {
	background: $background;
}

.neo-tooltip {
	cursor: help;
	display: flex;
	position: relative;
	max-width: 20px;
	border-radius: 50px;
	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;
	left: -7px;

	&.danger {
		@include bg-icon(map-get($theme-colors, "danger"));
	}

	&.info {
		@include bg-icon(map-get($theme-colors, "info"));
	}

	.tooltip-icon {
		i {
			color: white;
			font-size: 15px;
			display: flex;
		}
	}
}

.neo-tooltip-msg {
	position: absolute;
	text-align: center;
	visibility: hidden;
	white-space: normal;
	right: 0;
	min-width: 140px;
	font-size: 12px;
	font-weight: 100;
	line-height: 16px;
	background-color: map-get($theme-colors, "black");
	color: map-get($theme-colors, "white") !important;
	border-radius: 5px;
	padding: 5px 8px;
}

.neo-tooltip-msg:after {
	content: "";
	position: absolute;
	top: 100%;
	right: 45%;
	margin-left: -8px;
	width: 0;
	height: 0;
	border-top: 8px solid map-get($theme-colors, "black");
	border-right: 8px solid map-get($theme-colors, "link");
	border-left: 8px solid map-get($theme-colors, "link");
}

.neo-tooltip:hover .neo-tooltip-msg {
	position: absolute;
	visibility: visible;
	opacity: 0.9;
	bottom: 18px;
	right: 0;
	right: -65px;
}
