@import "../../../../assets/css/style.scss";

.voucher {
	&-input-percentage {
		width: 50% !important;
		.neo-input-valid,
		.neo-input-invalid {
			border-top-left-radius: 0px !important;
			border-bottom-left-radius: 0px !important;
		}
	}

	&-cpf {
		&-list {
			padding: 0px;
			max-height: 35vh;
			overflow-y: auto;

			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 6px $secondary-color;
				border-radius: 10px;
				background-color: map-get($theme-colors, "bgNeo");
			}

			&::-webkit-scrollbar {
				width: 6px;
				background-color: map-get($theme-colors, "bgNeo");
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				// -webkit-box-shadow: inset 0 0 6px $secondary-color;
				background-color: map-get($theme-colors, "grey-60");
			}
		}
	}
}

.vouchers-help-tooltip {
	background-color: map-get($theme-colors, "red");
	display: flex;
	width: 25px;
	height: 25px;
	margin: 0;
	padding: 0;
	div {
		display: flex;
		justify-content: center;
		width: 10px;
		height: 10px;
		button {
			padding: 0;
			border: none;
			cursor: pointer;
			background: transparent;
			.helper {
				color: map-get($theme-colors, "blueish-20");
			}
			.helper:hover {
				color: map-get($theme-colors, "grey-50");
			}
		}
		button:focus {
			outline: 0px auto -webkit-focus-ring-color;
			outline-offset: 0px;
		}
	}
}

.tooltipVouchers {
	background-color: map-get($theme-colors, "red") !important;
	position: relative;
	color: #ffffff !important;
	font-size: 12px;
	box-sizing: border-box;
	border-radius: 4px;
	font-weight: 500;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;

	> span {
		background-color: map-get($theme-colors, "red");
		position: absolute;
		text-align: center;
		visibility: hidden;
		color: map-get($theme-colors, "white") !important;
		border-radius: 5px;
		padding: 8px 10px 0 10px;
	}

	.helper-tooltip {
		visibility: hidden;
		background-color: map-get($theme-colors, "black");
		color: map-get($theme-colors, "white") !important;
		border-radius: 5px;
	}

	.helper-tooltip:after {
		content: "";
		position: absolute;
		display: flex;
		right: 50%;
		left: 50%;
		margin-left: -8px !important;
		box-sizing: border-box;
		border-top: 8px solid map-get($theme-colors, "black");
		border-right: 8px solid map-get($theme-colors, "link");
		border-left: 8px solid map-get($theme-colors, "link");
	}
}

.tooltipVouchers:hover .helper-tooltip {
	visibility: visible;
	opacity: 0.9;
	bottom: 20px;
	z-index: 999;
}

.tooltipVouchers:hover span {
	visibility: visible;
	opacity: 0.9;
	bottom: 40px;
	z-index: 999;
}
