/* prettier-ignore */
:root {
	--primary:       #6d4aed;
	--primary-10:    #e1d0fc;
	--primary-30:    #c0a3f8;
	--primary-60:    #9a76f3;
	--primary-130:   #5749bb;
	--primary-160:   #40498a;


	--secondary:     #00bfb5;
	--secondary-10:  #d2f0ec;
	--secondary-30:  #a2e0d9;
	--secondary-60:  #6cd0c7;
	--secondary-130: #0096a0;
	--secondary-160: #216d80;
}

/* Tablets */
@media (max-width: 1000px) {
	.App {
		max-width: 600px;
	}
	.file-item {
		width: calc(50% - 22px);
		height: 200px;
	}
}

/* Mobiles */
@media (max-width: 640px) {
	.App {
		max-width: 100%;
		padding: 0 15px;
	}
	.file-item {
		width: calc(100% - 22px);
		height: 200px;
	}
}
