@import "../../assets/css/style.scss";

.financial-header {
	margin: 30px 0 18px 0;
	overflow-x: hidden;
	overflow-y: hidden;

	.select-store-header {
		.position {
			position: absolute;
			width: 450px;
		}
	}

	@media (max-width: 1200px) {
		.select-store-header {
			.position {
				width: 350px;
			}
		}
	}

	@media (max-width: 990px) {
		.select-store-header {
			margin-bottom: 50px;
			.position {
				width: 90%;
			}
		}
	}

	.financial-title {
		width: 100%;
		font-size: 14px;
	}

	.financial-balance {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		.update-balance {
			display: flex;
			font-weight: bold;
			font-size: 15px;
			line-height: 19px;
			margin-bottom: 5px;
			label {
				margin: 5px 0;
			}

			.btn-icons-financial {
				cursor: pointer;
				border: none;
				background: transparent;
				font-size: 10px;
				color: map-get($theme-colors, "grey-65");
				box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);

				> i {
					font-size: 14px;
				}
			}

			.btn-icons-financial:focus {
				outline: 0px auto -webkit-focus-ring-color;
				outline-offset: 0px;
			}
		}

		.card-balance {
			border: 1px solid map-get($theme-colors, "grey-30");
			border-radius: 4px;
			width: 247px;
			height: 72px;
			display: flex;
			padding: 12px;
			.card-balance-icons {
				width: 48px;
				height: 48px;
				border-radius: 50%;
				margin-right: 16px;
				display: flex;
				justify-content: center;
				align-items: center;
				justify-self: center;
				color: map-get($theme-colors, "white");
			}

			.card-balance-data {
				> div {
					display: flex;
					> span {
						color: map-get($theme-colors, "blueish-80");
						font-size: 14px;
					}

					button {
						color: map-get($theme-colors, "grey-30");
						cursor: pointer;
						border: none;
						background: transparent;
						font-size: 20px;
						margin: -3px 0 0 5px;
						box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
					}

					button:focus {
						outline: 0px auto -webkit-focus-ring-color;
						outline-offset: 0px;
					}
				}

				> label {
					font-size: 18px;
					font-style: normal;
					font-weight: 700;
					line-height: 25px;
					position: relative;
					top: -6px;
				}
			}
		}
	}
	.text-balance-secondary {
		color: var(--secondary);
	}
	.text-balance-icons {
		color: map-get($theme-colors, "blueish-20");
		margin: 5px 0;
	}
	.text-balance-info {
		color: map-get($theme-colors, "info");
	}
	.bg-balance-info {
		background: map-get($theme-colors, "info");
	}
	.text-balence-success {
		color: map-get($theme-colors, "success");
	}
	.bg-balence-success {
		background: map-get($theme-colors, "success");
	}
	.bg-visibilityOff {
		margin: 0 5px 0 0;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: map-get($theme-colors, "grey-50");
	}
}

.financial-tabs {
	margin-top: 10px;
	border-bottom: 1px solid map-get($theme-colors, "grey-10");
	display: flex;
	white-space: nowrap;
	.change-tabs {
		width: 100%;
		display: flex;
		flex-direction: row;
		.disabled {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 150px;
			height: 40px;

			font-size: 16px;
			font-weight: 400;
			color: map-get($theme-colors, "grey-60");
		}
		.loading {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 150px;
			height: 40px;

			font-size: 16px;
			font-weight: 400;
			color: map-get($theme-colors, "grey-60");

			cursor: wait !important;
		}
		.actived {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 150px;
			height: 40px;
			border-bottom: 2px solid map-get($theme-colors, "grey-80");

			font-size: 16px;
			font-weight: 500;
			color: map-get($theme-colors, "grey-80");
		}
	}
}

.financial-buttons {
	display: flex;
	margin: 0;
	padding: 0;
	div {
		display: flex;
		justify-content: center;
		width: 40px;
		height: 40px;
		cursor: default;
		button {
			margin: 0;
			padding: 0;
			border: none;
			cursor: pointer;
			background: transparent;
			&:disabled {
				cursor: default;
			}
			.delete {
				color: map-get($theme-colors, "grey-50");
			}
			.download {
				color: map-get($theme-colors, "blueish-20");
			}
			.download:hover {
				color: map-get($theme-colors, "grey-50");
			}
		}
		button:focus {
			outline: 0px auto -webkit-focus-ring-color;
			outline-offset: 0px;
		}
	}
}

.financial-help-tooltip {
	display: flex;
	margin: 0;
	padding: 0;
	div {
		display: flex;
		justify-content: center;
		width: 10px;
		height: 10px;
		button {
			margin-top: -7px;
			margin-left: 5px;
			padding: 0;
			border: none;
			cursor: pointer;
			background: transparent;
			.helper {
				color: map-get($theme-colors, "blueish-20");
			}
			.helper:hover {
				color: map-get($theme-colors, "grey-50");
			}
		}
		button:focus {
			outline: 0px auto -webkit-focus-ring-color;
			outline-offset: 0px;
		}
	}
}

.financial-label {
	&.success {
		background: map-get($theme-colors, "success");
		box-sizing: border-box;
		color: map-get($theme-colors, "white");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.warning {
		background: map-get($theme-colors, "warning");
		box-sizing: border-box;
		color: map-get($theme-colors, "white");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.info {
		background: map-get($theme-colors, "info");
		box-sizing: border-box;
		color: map-get($theme-colors, "white");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.grey-65 {
		background: map-get($theme-colors, "grey-65");
		box-sizing: border-box;
		color: map-get($theme-colors, "white");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.danger {
		background: map-get($theme-colors, "danger");
		box-sizing: border-box;
		color: map-get($theme-colors, "white");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.dark {
		background: map-get($theme-colors, "grey-50");
		box-sizing: border-box;
		color: map-get($theme-colors, "white");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.outline-success {
		border: 1px solid map-get($theme-colors, "success");
		box-sizing: border-box;
		color: map-get($theme-colors, "success");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.outline-danger {
		border: 1px solid map-get($theme-colors, "danger");
		box-sizing: border-box;
		color: map-get($theme-colors, "danger");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.outline-grey-60 {
		border: 1px solid map-get($theme-colors, "grey-60");
		box-sizing: border-box;
		color: map-get($theme-colors, "grey-60");
		font-size: 12px;
		padding: 4px 16px;
		border-radius: 33px;
	}

	&.help-question {
		padding: 2px 5px 0 5px;
		color: map-get($theme-colors, "grey-50") !important;
		i {
			font-size: 16px;
			font-weight: 500;
		}
	}
}

.financial-text {
	&.title {
		margin: 0;
		align-items: center;
		font-weight: bold;
		font-size: 22px;
		color: map-get($theme-colors, "blueish-80");
	}

	&.title-detail {
		margin: 0;
		padding: 30px 30px 20px 25px;
		align-items: center;
		font-weight: bold;
		font-size: 26px;
		color: map-get($theme-colors, "blueish-80");
	}

	&.values {
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		text-align: center;
		color: map-get($theme-colors, "grey-60");
	}

	&.values-titles {
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		color: map-get($theme-colors, "grey-60");
	}

	&.text {
		color: map-get($theme-colors, "blueish-20");
	}

	&.text-credit {
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: map-get($theme-colors, "grey-80");
	}

	&.date {
		color: map-get($theme-colors, "blueish-80");
		font-size: 13px;
		margin: 0;
		font-weight: bold;
		color: #4b5f79;
	}

	&.typeTitle {
		color: map-get($theme-colors, "blueish-80");
		font-style: normal;
		font-weight: 500;
	}

	&.grey-70 {
		color: map-get($theme-colors, "grey-70");
		font-weight: 600;
	}

	&.date-hours {
		color: map-get($theme-colors, "blueish-20");
		font-weight: 400;
		font-size: 12px;
	}
}

.financial-detail {
	margin: 0 auto;
	&.table-header {
		border-top: 1px solid map-get($theme-colors, "grey-30");
		border-bottom: 1px solid map-get($theme-colors, "grey-30");
		padding: 8px 25px;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
	}

	&.table-body {
		display: flex;
		//padding: 0 20px;
		align-items: center;
		justify-content: center;
		height: 100px;
	}

	&.table-footer {
		border-bottom: 1px solid map-get($theme-colors, "grey-30");
	}
}

.financial-title-modal {
	font-weight: bold;
	font-size: 12px;
}

.tooltipNeo {
	cursor: help;
	display: block;
	position: relative;
	color: #ffffff !important;
	font-size: 12px;
	box-sizing: border-box;
	border-radius: 4px;
	font-weight: 500;
	padding: 8px 10px 0 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-align: center;

	> span {
		position: absolute;
		text-align: center;
		visibility: hidden;
		right: 0;
		background-color: map-get($theme-colors, "black");
		color: map-get($theme-colors, "white") !important;
		border-radius: 5px;
		padding: 5px 10px;
	}

	span:after {
		content: "";
		position: absolute;
		top: 100%;
		right: 12px;
		margin-left: -8px;
		box-sizing: border-box;
		border-top: 8px solid map-get($theme-colors, "black");
		border-right: 8px solid map-get($theme-colors, "link");
		border-left: 8px solid map-get($theme-colors, "link");
	}

	.helper-tooltip {
		position: absolute;
		text-align: center;
		visibility: hidden;
		right: 0;
		background-color: map-get($theme-colors, "black");
		color: map-get($theme-colors, "white") !important;
		border-radius: 5px;
		padding: 5px 10px;
	}

	.helper-tooltip:after {
		content: "";
		position: absolute;
		top: 100%;
		right: 7%;
		margin-left: -8px;
		box-sizing: border-box;
		border-top: 8px solid map-get($theme-colors, "black");
		border-right: 8px solid map-get($theme-colors, "link");
		border-left: 8px solid map-get($theme-colors, "link");
	}
}
.tooltipNeo:hover .helper-tooltip {
	visibility: visible;
	opacity: 0.9;
	bottom: 20px;
	right: -10px;
	z-index: 999;
}

.tooltipNeo:hover span {
	visibility: visible;
	opacity: 0.9;
	bottom: 40px;
	right: 0;
	z-index: 999;
}

.progress-initial {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 0;
	margin: 0 auto;

	.timeline {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		background-color: map-get($theme-colors, "grey-light");

		div {
			display: flex;
			flex-direction: column;

			label {
				margin: 0 auto;
				padding: 10px 0;
				font-size: 16px;
				font-weight: 500;
				color: map-get($theme-colors, "grey-60");
			}

			&.start-stepOne {
				color: map-get($theme-colors, "success");
				border: solid 3px map-get($theme-colors, "success");
				width: 45px;
				height: 45px;
				border-radius: 50%;
				margin: 0 80px 0 80px;
				padding: 8px 0;

				span {
					margin: 0 auto;
					font-size: 15px;
					font-weight: bold;
				}
				&::before {
					width: 278px;
					height: 3px;
					background: map-get($theme-colors, "grey-60");
					content: "";
					display: block;
					position: absolute;
					top: 98px;
					left: 156px;
				}
			}
			&.finish-stepOne {
				color: map-get($theme-colors, "grey-60");
				border: solid 3px map-get($theme-colors, "grey-60");
				width: 45px;
				height: 45px;
				border-radius: 50%;
				margin: 0 80px 0 80px;
				padding: 8px 0;

				span {
					margin: 0 auto;
					font-size: 15px;
					font-weight: bold;
				}
			}
			&.start-stepTwo {
				border: solid 3px map-get($theme-colors, "success");
				background: map-get($theme-colors, "success");
				width: 45px;
				height: 45px;
				border-radius: 50%;
				margin: 0 80px 0 80px;
				padding: 8px 0;

				span {
					margin: 0 auto;
					i {
						margin-left: 8px;
						color: map-get($theme-colors, "white");
					}
				}

				&::after {
					width: 278px;
					height: 3px;
					background: map-get($theme-colors, "success");
					content: "";
					display: block;
					position: absolute;
					top: 98px;
					left: 156px;
				}
			}
			&.finish-stepTwo {
				color: map-get($theme-colors, "success");
				border: solid 3px map-get($theme-colors, "success");
				width: 45px;
				height: 45px;
				border-radius: 50%;
				margin: 0 80px 0 80px;
				padding: 8px 0;

				span {
					margin: 0 auto;
					font-size: 15px;
					font-weight: bold;
				}
			}
		}
	}
}

.btn-export-financial {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border: 1px solid #e9ecec;
	background: transparent;
	color: #747e8b;
	width: 190px;
	height: 40px;
	cursor: pointer;
}
