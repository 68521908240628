.dropdown-menu {
	box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
	border: 0;
	.dropdown-item {
		font-size: 0.9rem;
		padding: 0.6rem 1.6rem;
		i {
			float: left;
			margin-right: 0.6rem;
			font-size: 1.3rem;
		}
	}
}
.dropdown-menu-media {
	padding: 0;
	width: 26rem;

	.dropdown-menu-header {
		border-top-left-radius: 0.267rem;
		border-top-right-radius: 0.267rem;
		background: rgba(map-get($theme-colors, "blueish-80"), 0.1);
		.dropdown-header {
			padding: 0.75rem;
			.notification-title {
				font-weight: bold;
			}
		}
	}

	.media-list {
		.media {
			padding: 0.75rem;
			display: flex;
			align-items: center;
			transition: all 0.5s ease;
			cursor: pointer;
			border-bottom: 1px solid rgba(map-get($theme-colors, "blueish-80"), 0.1);

			&:hover {
				background-color: rgba(map-get($theme-colors, "blueish-80"), 0.04);
			}

			.media-icon {
				background-color: rgba(map-get($theme-colors, "blueish-80"), 0.2);
				width: 40px;
				height: 40px;
				border-radius: 25px;
				position: relative;
				margin-right: 0.75rem;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: map-get($theme-colors, "blueish-80");
					font-size: 22px;
				}
			}
			.media-body {
				h5 {
					font-weight: bold;
					font-size: 0.9rem;
					margin: 0;
					color: map-get($theme-colors, "blueish-80");
				}
				.media-date {
					font-size: 0.7rem;
					font-weight: normal;
					color: rgba(map-get($theme-colors, "blueish-80"), 0.5);
				}
			}
			.media-status {
				background-color: rgba(map-get($theme-colors, "blueish-80"), 0.1);
				width: 14px;
				height: 14px;
				border-radius: 10px;
				position: relative;
				margin-left: 0.75rem;
				position: relative;
			}

			&.new {
				.media-icon {
					background-color: var(--primary);
					i {
						color: map-get($theme-colors, "white");
					}
				}
				.media-body {
					h5 {
						color: var(--primary);
					}
				}
				.media-status {
					&:after {
						display: block;
						content: "";
						width: 8px;
						height: 8px;
						border-radius: 4px;
						background-color: var(--primary);
						position: absolute;
						top: 50%;
						left: 50%;
						opacity: 1;
						transform: translate(-50%, -50%);
						animation: blink-animation 1s infinite;
						-webkit-animation: blink-animation 1s infinite;
					}
				}
			}
		}
	}
}

.config-header {
	width: 250px;

	.config-nav-link {
		color: map-get($theme-colors, "blueish-80");
	}
}

@keyframes blink-animation {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
@-webkit-keyframes blink-animation {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
