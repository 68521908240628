@import "../../../../assets/css/style.scss";

.card-promotions {
	background: map-get($theme-colors, "white");
	border: 2px solid map-get($theme-colors, "grey-20");
	box-sizing: border-box;
	border-radius: 5px;
	p {
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 16px;
		color: map-get($theme-colors, "blueish-80");
		margin-bottom: 20px;
	}
	.close-img {
		position: absolute;
		top: 5px;
		right: 5px;
		border: none;
		background-color: map-get($theme-colors, "danger");
		color: map-get($theme-colors, "white");
		border-radius: 50px;
		width: 20px;
		height: 20px;
		padding: 0;
		/* opacity: 0.3; */
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
		&:before,
		&:after {
			position: absolute;
			content: " ";
			height: 10px;
			padding: -8px;
			width: 2px;
			margin-top: -5px;
			margin-left: -1px;
			background-color: map-get($theme-colors, "white");
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
}
