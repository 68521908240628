@import "../../assets/css/style.scss";

.order-table {
	&-client {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
			color: map-get($theme-colors, "grey-60");
		}
		&-name {
			color: map-get($theme-colors, "grey-80");
			font-weight: bold;
		}
	}
	&-order {
		line-height: 12px;
		font-size: 12px;
		&-code {
			display: inline-block;
			font-weight: bold;
			height: 12px;
			color: map-get($theme-colors, "blueish-80");
			margin-top: 0;
			margin-bottom: 1em;
			&:hover {
				color: map-get($theme-colors, "blueish-80");
			}
		}
	}
	&-status {
		line-height: 14px;
		font-size: 12px;
		color: map-get($theme-colors, "icons");
		text-align: center;
		p:last-child {
			margin-bottom: 0;
		}
	}
	&-products {
		display: flex;
		background: map-get($theme-colors, "grey-50");
		border-radius: 33px;
		color: map-get($theme-colors, "white");
		height: 24px;
		align-self: center;
		justify-content: center;
		align-items: center;
		line-height: 24px;
		p:last-child {
			padding: 0px 16px;
			margin-bottom: 0;
		}
	}
	&-modality {
		line-height: 14px;
		font-size: 12px;
		p:last-child {
			display: flex;
			justify-content: center;
			margin-bottom: 0;
		}
		&-title {
			font-weight: bold;
			word-break: keep-all;
			padding: 0;
			margin: 0;
		}
		&-subtitle {
			font-weight: normal;
			word-break: keep-all;
			padding: 0;
			margin: 0;
		}
	}
	&-estimate {
		line-height: 12px;
		font-size: 12px;
		p {
			text-align: center;
		}
	}
	&-origin {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
		}
		&-title {
			font-weight: bold;
			line-height: 20px;

			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&-total {
		line-height: 12px;
		font-size: 12px;
		p:last-child {
			margin-bottom: 0;
		}
	}
	&-payment {
		display: flex;
		line-height: 14px;
		font-size: 12px;
		flex-direction: column;
		text-align: center;
		align-items: center;
		align-self: center;
		p:last-child {
			margin-bottom: 0;
		}
		span {
			margin-bottom: 0.8em;
		}
	}
}

.legacy-order-status {
	&-Canceled {
		color: map-get($theme-colors, "danger");
	}
	&-Finalized {
		color: map-get($theme-colors, "success");
	}
	&-Shipping,
	&-ReadyForPickup {
		color: map-get($theme-colors, "info");
	}
	&-AwaitingInvoiceCreation,
	&-InvoiceCreated,
	&-Separated {
		color: map-get($theme-colors, "warning");
	}
}

.order-status {
	&-Created,
	&-WaitingApproval {
		color: map-get($theme-colors, "blueish-20");
	}
	&-WaitingSeparation {
		color: map-get($theme-colors, "warning");
	}
	&-WaitingInvoice {
		color: map-get($theme-colors, "orange-130");
	}
	&-WaitingDispatch,
	&-WaitingReturnGoods {
		color: map-get($theme-colors, "info");
	}
	&-WaitingDelivery,
	&-ReturningGoods {
		color: map-get($theme-colors, "blue-130");
	}
	&-Finalized {
		color: map-get($theme-colors, "success");
	}
	&-Canceled,
	&-WaitingCancel {
		color: map-get($theme-colors, "danger");
	}
}
