.progress-orders {
	z-index: 0;
	.text-link-timeline {
		border: none;
		background: transparent;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		text-decoration-line: underline;
		color: map-get($theme-colors, "blueish-20");
		cursor: pointer;
	}
	.text-link-timeline:focus {
		border: none;
		box-shadow: 0 0 0 0;
		border: 0 none;
		outline: 0;
	}
	.timeline {
		padding: 0;
		height: 60px;
		display: flex;
		margin-top: 30px;
		margin-left: -20px;
		z-index: -1;

		.btn-light,
		.btn-primary {
			width: 300px;
		}

		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			display: flex;
			width: 100%;
			display: flex;
			justify-content: center;
			overflow: hidden;
			text-transform: uppercase;
			&::before {
				width: 77%;
				height: 3px;
				background: #efefef;
				content: "";
				display: block;
				position: absolute;
				top: 122px; //barra cinza
			}
			li {
				font-size: 12px;
				text-align: center;
				flex: 1;
				position: relative;

				span {
					display: block;
					margin-top: 20px;
					color: #9b9b9b;
					height: 100px;
					font-size: 11px;
					width: 145px;
				}

				&::before {
					position: absolute;
					width: 18px;
					height: 18px;
					border-radius: 9px;
					display: block;
					content: "";
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					border: 2px solid #efefef;
					background: #fff;
				}
				&.started {
					&::before {
						border: 2px solid map-get($theme-colors, "grey-30");
						background: map-get($theme-colors, "grey-30");
						z-index: 2;
					}
					&::after {
						width: 100%;
						height: 3px;
						content: "";
						position: absolute;
						top: 7px;
					}
					span {
						color: map-get($theme-colors, "grey-30");
						font-size: 11px;
						font-weight: bold;
						width: 145px;
					}
				}
				&.validated {
					&::before {
						border: 2px solid map-get($theme-colors, "success");
						background: map-get($theme-colors, "success");
						z-index: 2;
					}
					&::after {
						width: 100%;
						height: 3px;
						content: "";
						position: absolute;
						top: 7px;
					}
					span {
						color: map-get($theme-colors, "success");
						font-size: 11px;
						font-weight: bold;
						width: 145px;
					}
				}
				&.success {
					&::before {
						border: 2px solid map-get($theme-colors, "success");
						background: map-get($theme-colors, "success");
						z-index: 2;
					}
					&::after {
						width: 95%;
						height: 3px;
						background: map-get($theme-colors, "success");
						content: "";
						display: block;
						position: absolute;
						top: 7px;
						right: 70px;
						z-index: 1;
					}
					span {
						color: map-get($theme-colors, "success");
						font-size: 11px;
						font-weight: bold;
						width: 145px;
					}
				}
				&.waiting {
					&::before {
						border: 2px solid map-get($theme-colors, "grey-30");
						background: map-get($theme-colors, "grey-30");
						z-index: 2;
					}
					&::after {
						width: 95%;
						height: 3px;
						background: map-get($theme-colors, "grey-30");
						content: "";
						display: block;
						position: absolute;
						top: 7px;
						right: 70px;
						z-index: 1;
					}
					span {
						color: map-get($theme-colors, "grey-30");
						font-size: 11px;
						font-weight: bold;
						width: 145px;
					}
				}
				&.err {
					&::before {
						border: 2px solid map-get($theme-colors, "danger");
						background: map-get($theme-colors, "danger");
						z-index: 2;
					}
					&::after {
						width: 95%;
						height: 3px;
						background: map-get($theme-colors, "danger");
						content: "";
						display: block;
						position: absolute;
						top: 7px;
						right: 70px;
						z-index: 1;
					}
					span {
						color: map-get($theme-colors, "danger");
						font-size: 11px;
						font-weight: bold;
						width: 145px;
					}
				}
				&.warning {
					&::before {
						border: 2px solid map-get($theme-colors, "warning");
						background: map-get($theme-colors, "warning");
						z-index: 2;
					}
					&::after {
						width: 95%;
						height: 3px;
						background: map-get($theme-colors, "warning");
						content: "";
						display: block;
						position: absolute;
						top: 7px;
						right: 70px;
						z-index: 1;
					}
					span {
						color: map-get($theme-colors, "warning");
						font-size: 11px;
						font-weight: bold;
						width: 145px;
					}
				}
			}
		}
	}
}
