.breadcrumb {
	background-color: map-get($theme-colors, "link");
	margin-bottom: 0px;
	margin-top: 0.5rem;
	padding: 0.75rem 0;
	.breadcrumb-item {
		color: map-get($theme-colors, "grey-50");
		padding-left: -50px !important;
		&.active {
			color: map-get($theme-colors, "grey-90");
			padding-left: -50px !important;
		}
	}
}
.breadcrumb-item + .breadcrumb-item::before {
	@extend .m-icon;
	content: "";
	font-size: 14px;
	font-weight: bold;
	color: map-get($theme-colors, "grey-50");
}
.link-breadcrumb {
	margin-left: -20px;
	display: flex;
	justify-content: center;
	align-items: center;
	i {
		font-size: 20px;
		font-weight: 500;
		margin-right: 5px;
		color: map-get($theme-colors, "grey-50");
	}
}
