.header {
	&.header-primary {
		padding: 0;
		background-color: var(--primary);
		position: relative;

		.navbar-toggler {
			border-color: var(--primary);
			color: map-get($theme-colors, "white");
			display: flex;
			align-items: center;
			cursor: pointer;

			.material-icons {
				font-size: 20px;
			}
		}
		.navbar-toggler:focus {
			outline: none;
		}
		.navbar-wrapper {
			margin-left: auto;
		}

		.brand {
			display: inline-block;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		.user-nav {
			color: map-get($theme_colors, "white");
			padding: 0.5rem 0;
			margin-right: 0.6rem;
			text-align: right;
			.user-name {
				display: block;
				font-weight: 700;
			}
			.user-role {
				font-size: 0.85rem;
			}
		}
		.user-avatar {
			img {
				border-radius: 20px;
				height: 40px;
				width: 40px;
			}
		}

		.navbar-header {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			padding: 0;
			z-index: 1000;
			.navbar-brand {
				margin-right: 0;
			}
		}
		.dropdown-notification {
			.material-icons {
				font-size: 28px;
			}
			.badge-up {
				position: absolute;
				top: 4px;
				right: 4px;
				width: 16px;
				height: 16px;
				text-align: center;
				padding: 0;
				line-height: 16px;
			}
		}
	}
}

.fixed-notifications {
	background-color: map-get($theme_colors, "info");
	&-container {
		height: 40px;
		&-icon {
			color: map-get($theme_colors, "white");
			font-size: 24px;
		}
		&-text {
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			color: map-get($theme_colors, "white");
			a {
				font-weight: 700;
				text-decoration: underline !important;
				color: map-get($theme_colors, "white");
			}
		}
	}
}

.navbar {
	&.navbar-primary {
		padding: 0;
		background-color: var(--secondary);
	}
}

.horizontal-menu {
	background-color: map-get($theme-colors, "bgNeo");
	border-bottom: 1px solid map-get($theme-colors, "grey-20");
	padding: 0;
	&.navbar-expand-lg {
		.navbar-nav {
			& > li {
				padding: 10px 0;
			}
			li {
				i {
					width: 1.6rem !important;
					margin-right: 0.4rem;
				}

				& > a.nav-link {
					color: map-get($theme-colors, "blueish-20");
					font-size: 0.9rem;
					padding-right: 0.85rem;
					padding-left: 0.85rem;
					display: flex;
					align-items: center;
					height: 100%;
					transition: all 0.5s ease;
					cursor: pointer;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;

					&:hover {
						background-color: map-get($theme-colors, "light");
					}
				}
				&.show > a.nav-link {
					background-color: map-get($theme-colors, "light");
				}

				&.dropdown {
					cursor: pointer;
					.dropdown-toggle {
						&:after {
							content: "keyboard_arrow_down";
							font-family: "Material Icons";
							font-weight: normal;
							font-style: normal;
							font-size: 16px;
							line-height: 1;
							border: 0;
							height: auto;
							margin: 0 0.55rem;
						}
					}
					.dropdown-menu {
						border: 1px solid map-get($theme-colors, "grey-20");
						padding: 0.7rem 0;
						top: calc(100% - 10px);
						.nav-link {
							padding-right: 2rem;
							white-space: nowrap;
							cursor: pointer;
							i {
								width: auto;
								right: 0;
								margin: 0;
							}
						}
						.dropdown-item {
							font-size: 0.9rem;
							padding: 0.7rem 1.3rem;

							&:hover {
								background-color: rgba(map-get($theme-colors, "light"), 0.4);
							}
						}
					}
					.dropdown-submenu {
						> a.dropdown-item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding-right: 0;
							display: inline-block;
							color: map-get($theme-colors, "blueish-20");
							font-weight: normal;
							font-style: normal;
							margin: 0 0.55rem;

							&:active {
								background-color: rgba(map-get($theme-colors, "light"), 0.4);
								color: map-get($theme-colors, "blueish-80");
							}

							&:after {
								content: "keyboard_arrow_right";
								font-family: "Material Icons";
								font-weight: normal;
								font-style: normal;
								font-size: 16px;
								line-height: 1;
								border: 0;
								height: auto;
								margin: 0 0.55rem;
							}
						}
						.nav-link:hover ul {
							display: block;
						}
						.dropdown-menu {
							left: 100%;
							top: 0;
						}
					}
				}
			}
		}
	}
}

.dropright-menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 0;
	color: map-get($theme-colors, "blueish-20");
	font-weight: normal;
	font-style: normal;

	.material-icons {
		font-size: 16px;
		font-weight: normal;
	}
}

.dropdown-submenu {
	display: flex;
}

.dropright {
	list-style: none;
	font-weight: normal;
	font-style: normal;
	margin: 0;
	margin-top: 30px;
	margin-left: -450px;
	padding: 5px;
	background-color: map-get($theme-colors, "white");
	box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
	li {
		position: relative;
		a {
			margin-left: -10px;
			color: map-get($theme-colors, "blueish-20");
		}
	}
}

.dropright-menu {
	&:hover > ul {
		display: block;
	}
	> ul {
		background-color: map-get($theme-colors, "white");
		box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
		display: none;
		min-width: 160px;
		position: absolute;
		list-style: none;
		top: 5px;
		margin: 0;
		padding: 0;
	}

	.material-icons {
		position: absolute;
		font-size: 16px;
		font-weight: normal;
		right: 10px;
	}
}

ul.menu,
.menu li,
.menu a {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
}

ul.menu ul {
	position: absolute;
	display: none;
	width: 230px;
	z-index: 2;
	border-radius: 5px;
}
ul.menu {
	float: left;
	font-size: 12px;
	padding: 0px;
}

.menu li {
	float: left;
	width: auto;
	position: relative;
}

.menu li a {
	display: block;
	padding: 0 20px;
	line-height: 45px;
	height: 45px;
	float: left;
	transition: all 0.1s linear;
	border-radius: 5px;
}
.menu li:hover > ul.submenu {
	display: block;
	top: 45px;
	padding: 0px;
}

.submenu {
	background-color: map-get($theme-colors, "white");
	box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
}
.menu ul.submenu a {
	background-color: map-get($theme-colors, "white");
	border: none;
	width: 230px !important;
	padding: 0px;
}
.menu li:hover > ul.menu-submenu {
	display: block;
	top: 0;
	left: 230px;
	padding: 0px;
	width: 230px;
}

.menu ul.menu-submenu a {
	width: 200px;
	padding: 0 20px;
}

.menu ul.submenu li a {
	border-radius: 0;
}

.menu ul.submenu li:last-child a {
	border-radius: 0 0 5px 5px;
}

.menu ul.submenu li:first-child a {
	border-radius: 5px 5px 0 0;
}

.menu a {
	color: #000;
}
.menu li:hover > a {
	color: map-get($theme-colors, "white");
}

.submenu a {
	color: map-get($theme-colors, "white");
	background-color: map-get($theme-colors, "blueish-20");
}
.submenu li:hover > a {
	background-color: map-get($theme-colors, "light");
}

.menu-submenu {
	background-color: map-get($theme-colors, "white");
	box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, 0.2);
}
.menu-submenu a {
	color: map-get($theme-colors, "white");
}
.menu-submenu li:hover > a {
	background-color: map-get($theme-colors, "light");
}

@media (max-width: 992px) {
	.horizontal-menu.navbar-expand-lg .navbar-nav > li {
		padding: 0;
	}
	ul.menu,
	.menu li,
	.menu a {
		width: 230px;
	}
	ul.menu ul {
		display: none;
		width: 230px;
		padding: 30px;
	}
	.menu li a {
		transition: all 0.1s linear;
	}
	ul.menu ul {
		position: static;
	}
	.menu li:hover > ul.menu-submenu {
		padding: 0;
		margin-left: -200px;
		margin-top: 45px;
	}
}
