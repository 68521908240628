.title-tutorial{
    font-size:16px !important;
}
.description-tutorial{
    font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
text-overflow: ellipsis;

/* Required for text-overflow to do anything */
white-space: nowrap;
overflow: hidden;
color:map-get($theme-colors, "grey-30");
}