.edit-plugin {
	position: fixed;
	top: 0;
	right: -450px;
	height: 100vh;
	background-color: map-get($theme-colors, "white");
	z-index: 2;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
	padding: 24px;
	min-width: 450px;
	max-width: 450px;
	transition-duration: 0.5s;
	overflow-x: hidden;
	overflow-y: scroll;
	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: #999;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #999;
	}
}
.edit-plugin.active-plugin {
	transition-duration: 0.5s;
	transform: translate(-100%);
	// animation: open 1s;
}
