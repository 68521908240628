@import "../../assets/css/style.scss";

.integration-state {
	height: 100%;
}

.integrations-list {
	height: 80%;
	overflow-x: hidden;
	overflow-y: auto;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: map-get($theme-colors, "grey-60");
	}
}

.load-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: hsla(0, 0%, 100%, 0.9);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	z-index: 1;
	opacity: inherit;

	.load-modal-card {
		height: 100%;
		display: flex;
		color: black;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		> div {
			margin: 0;
			padding: 0;
			.rotate {
				margin-left: -20px;
				margin-top: -57px;
				animation: loading 2s linear infinite;
			}
			@keyframes loading {
				0% {
					transform: rotate(0);
				}
				100% {
					transform: rotate(360deg);
				}
			}
		}

		.load-modal-text {
			padding-top: 24px;
			margin: 0;
			width: 311px;
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 33px;
			text-align: center;
			color: map-get($theme-colors, "grey-80");
		}
	}
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
	position: relative;
	left: -9999px;
	width: 15px;
	height: 15px;
	border-radius: 10px;
	background-color: map-get($theme-colors, "secondary");
	color: map-get($theme-colors, "secondary");
	box-shadow: 9999px 0 0 -5px;
	animation: dot-pulse 1.5s infinite linear;
	animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
	content: "";
	display: inline-block;
	position: absolute;
	top: 0;
	width: 15px;
	height: 15px;
	border-radius: 10px;
	background-color: map-get($theme-colors, "secondary");
	color: map-get($theme-colors, "secondary");
}
.dot-pulse::before {
	box-shadow: 9984px 0 0 -5px;
	animation: dot-pulse-before 1.5s infinite linear;
	animation-delay: 0s;
}
.dot-pulse::after {
	box-shadow: 10014px 0 0 -5px;
	animation: dot-pulse-after 1.5s infinite linear;
	animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
	0% {
		box-shadow: 9984px 0 0 -5px;
	}
	30% {
		box-shadow: 9984px 0 0 2px;
	}
	60%,
	100% {
		box-shadow: 9984px 0 0 -5px;
	}
}
@keyframes dot-pulse {
	0% {
		box-shadow: 9999px 0 0 -5px;
	}
	30% {
		box-shadow: 9999px 0 0 2px;
	}
	60%,
	100% {
		box-shadow: 9999px 0 0 -5px;
	}
}
@keyframes dot-pulse-after {
	0% {
		box-shadow: 10014px 0 0 -5px;
	}
	30% {
		box-shadow: 10014px 0 0 2px;
	}
	60%,
	100% {
		box-shadow: 10014px 0 0 -5px;
	}
}
