.stores-container {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 765px) {
	.stores-container {
		display: flex;
		flex-direction: column;
	}
}
