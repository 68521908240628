.settings {
	&-dropdown {
		&.show {
			i {
				font-family: "Material Icons";
			}
		}
		&-icon {
			color: map-get($theme-colors, "white");
		}
		&-menu {
			width: 270px;
			margin-top: 6px;
			padding: 12px 0 0;
			box-shadow: 0px 10px 14px 0 rgba(25, 42, 70, 0.1);
		}
		&-triangle {
			height: 40px;
			width: 40px;
			transform: scale(0.4);
			position: absolute;
			top: -24px;
			right: 7.5px;
		}
		&-header {
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 25px;
			color: map-get($theme-colors, "blueish-80"); // deep blue #4B5F79

			padding: 12px 0 16px 24px;
		}
		&-divider {
			margin: 0px;
		}
		&-item {
			// !important pois entra em conflito com CSS da classe dropdown-menu e dropdown-item
			font-style: normal !important;
			font-weight: normal !important;
			font-size: 14px !important;
			line-height: 19px !important;
			color: map-get(
				$theme-colors,
				"blueish-80"
			) !important; //Deep Blue #4B5F79
			padding: 12px 0 12px 24px !important;
			&:hover {
				background-color: map-get($theme-colors, "grey-10");
			}
			&:first-of-type {
				padding: 16px 0 12px 24px !important;
			}
			// O logout conta como &-item, &:nth-last-of-type(2) é para encontrar o último item antes do "logout"
			&:nth-last-of-type(2) {
				padding: 12px 0 16px 24px !important;
			}
			// Para corrigir (parar de sobrescrever) quando o dropdown possuir apenas um "&-item" antes do "logout"
			&:first-of-type:nth-last-of-type(2) {
				padding: 16px 0 16px 24px !important;
			}
			&.logout {
				color: map-get($theme-colors, "danger") !important;
				padding: 16px 0 24px 24px !important;
			}
		}
	}
}
