.stepper {
	position: fixed;
	z-index: 100;
	width: 100%;
	bottom: 0;
	left: 0;
	background: map-get($theme-colors, "white");
	padding: 10px 0;
	border-top: 1px solid map-get($theme-colors, "grey-10");

	.container {
		position: relative;
	}

	.skip-step {
		position: absolute;
		bottom: 60px;
		right: 15px;
		color: map-get($theme-colors, "grey-65");
		&:hover {
			color: map-get($theme-colors, "grey-65");
		}
	}

	.timeline {
		height: 40px;
		display: flex;
		position: relative;
		.btn-light,
		.btn-primary {
			width: 100px;
		}
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			display: flex;
			width: calc(100% - 200px);
			position: relative;
			overflow: hidden;

			&::before {
				width: 100%;
				height: 3px;
				background: map-get($theme-colors, "grey-10");
				content: "";
				display: block;
				position: absolute;
				top: 7px;
			}
			li {
				font-size: 12px;
				text-align: center;
				flex: 1;
				position: relative;

				span {
					color: map-get($theme-colors, "grey-65");
					display: block;
					margin-top: 20px;
				}

				&::before {
					position: absolute;
					width: 18px;
					height: 18px;
					border-radius: 9px;
					display: block;
					content: "";
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					border: 2px solid map-get($theme-colors, "grey-20");
					background: map-get($theme-colors, "white");
				}
				&.completed {
					&::before {
						border: 2px solid map-get($theme-colors, "success");
						background: map-get($theme-colors, "success");
					}
					&::after {
						width: 100%;
						height: 3px;
						background: map-get($theme-colors, "success");
						content: "";
						display: block;
						position: absolute;
						top: 7px;
					}
					span {
						color: map-get($theme-colors, "success");
						font-weight: bold;
					}
				}
				&.active {
					&::before {
						border: 2px solid map-get($theme-colors, "grey-20");
						background: map-get($theme-colors, "green-130");
						z-index: 2;
					}
					&::after {
						width: 50%;
						height: 3px;
						background: map-get($theme-colors, "success");
						content: "";
						display: block;
						position: absolute;
						top: 7px;
						z-index: 1;
					}
					span {
						color: map-get($theme-colors, "green-130");
						font-weight: bold;
					}
				}
			}
		}
	}
}
