.download-invoice {
	border: 2px solid map-get($theme-colors, "grey-20");
	border-radius: 5px;
	padding: 0;
	margin: 5px 0;
	height: 40px;
	display: flex;
	justify-content: space-between;

	.download-invoice-label {
		padding: 0 10px;
		display: flex;
		align-items: center;
		align-self: center;
		align-content: center;
		color: map-get($theme-colors, "grey-60");

		> span {
			width: 100px;
			margin-top: 10px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		> i {
			padding: 10px 0;
			margin: 0;
			color: map-get($theme-colors, "grey-60");
		}
	}

	.download-invoice-icons {
		padding: 0 10px;
		margin: 0;
		display: flex;
		align-items: center;
		align-self: center;
		align-content: center;

		> button {
			cursor: pointer;
			padding-top: 10px;
			background: map-get($theme-colors, "link");
			border: none;
			outline: none;
		}

		> a {
			cursor: pointer;
			padding-top: 10px;
			color: map-get($theme-colors, "blueish-20");
		}
	}
}
