@import "../../assets/css/style.scss";

.card-header-search {
	.filter-btn {
		margin-right: 16px;
		width: 190px;
		height: 40px;
		border: none;
		border-radius: 4px;
		outline: none;
		cursor: pointer;
		font-weight: 400;
		font-size: 14px;
		line-height: 19px;
		i {
			font-size: 24px !important;
			margin-right: 8px;
		}
		&-import {
			background-color: map-get($theme-colors, "blue-10");
			color: map-get($theme-colors, "info");
		}
		&-filter {
			background-color: map-get($theme-colors, "grey-80");
			border: 1px solid map-get($theme-colors, "grey-80");
			color: map-get($theme-colors, "white");
		}
		&-clear {
			background-color: map-get($theme-colors, "grey-60");
			color: map-get($theme-colors, "white");
		}
		&-export {
			background-color: transparent;
			border: 1px solid map-get($theme-colors, "grey-70");
			color: map-get($theme-colors, "blueish-50");
			i {
				color: map-get($theme-colors, "blueish-20");
			}
			.fa-spinner {
				font-size: 14px !important;
			}
		}
	}

	.header-search {
		.filter-block {
			width: 190px !important;
			&-label {
				font-weight: 700;
				font-size: 12px;
				line-height: 16px;
				color: map-get($theme-colors, "blueish-80");
				margin-bottom: 8px;
			}
			input,
			select {
				font-weight: 400 !important;
				font-size: 14px !important;
				line-height: 19px !important;
				color: map-get($theme-colors, "blueish-50") !important;
			}
		}
	}
}
