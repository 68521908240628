.neo-form-row {
	//esta classe será descontinuada
	height: 90px;
}
.neo-form {
	position: relative;
	width: 100%;
	input:focus,
	button:focus {
		box-shadow: 0 0 0 0;
		outline: 0;
		border: none;
	}

	.input-form-label {
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		color: map-get($theme-colors, "blueish-80") !important;
	}

	.neo-form-search-label {
		font-weight: 700;
		font-size: 12px;
		line-height: 16px;
		color: map-get($theme-colors, "blueish-80");
		margin-bottom: 8px;
	}

	.neo-form-search {
		display: flex;
		flex-direction: row;
		position: relative;

		.neo-icons-search {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			position: absolute;
			margin-top: 8px;
			> button {
				border: none;
				background: transparent;
				> i {
					color: map-get($theme-colors, "blueish-20");
				}
			}
		}

		.neo-input-search {
			width: 100%;
			height: 40px;
			padding-left: 33px;
			border: 2px solid map-get($theme-colors, "grey-20");
			border-radius: 4px;
			color: map-get($theme-colors, "blueish-80");
			&:focus,
			&:focus-within,
			&:active {
				border: 2px solid var(--secondary);
				border-radius: 4px;
				box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
				color: map-get($theme-colors, "blueish-20");
			}
			&::placeholder {
				color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
			}
		}

		.neo-icons-cancel {
			height: 100%;
			right: 6px;
			top: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			> button {
				border: none;
				background: transparent;
				cursor: pointer;
				> i {
					color: map-get($theme-colors, "grey-50");
					font-size: 18px;
				}
			}
		}
	}

	.neo-form-input {
		display: flex;
		flex-direction: row;
		width: 100%;

		.select-arrow {
			cursor: pointer;
			&-lg {
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				background: url("../images/arrow-down-select.svg") no-repeat 98%;
			}

			&-sm {
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				background: url("../images/arrow-down-select.svg") no-repeat 96%;
			}
		}

		.neo-input-valid {
			width: 100%;
			height: 40px;
			padding-left: 10px;
			border: 2px solid map-get($theme-colors, "grey-20");
			border-radius: 5px;
			color: map-get($theme-colors, "blueish-80");

			&:focus,
			&:active {
				outline: none;
				border: 2px solid var(--secondary) !important;
				border-radius: 5px;
				box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
				color: map-get($theme-colors, "blueish-80");
			}
			&::placeholder {
				color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
			}
		}

		.neo-input-invalid {
			width: 100%;
			height: 40px;
			border: 2px solid map-get($theme-colors, "danger") !important;
			border-radius: 5px;
			color: map-get($theme-colors, "blueish-80");

			&:focus,
			&:active {
				outline: none;
				border: 2px solid map-get($theme-colors, "danger") !important;
				border-radius: 5px;
				box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "danger"), 0.25) !important;
				color: map-get($theme-colors, "blueish-80");
			}

			&::placeholder {
				color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
			}
		}

		.neo-input-disabled {
			width: 100%;
			height: 40px;
			padding: 8px 8px 8px 10px;
			border: 2px solid map-get($theme-colors, "grey-20");
			border-radius: 5px;
			color: map-get($theme-colors, "blueish-80");
			background-color: map-get($theme-colors, "grey-10");
			&:hover {
				border: 2px solid map-get($theme-colors, "grey-20");
			}

			&:focus,
			&:active {
				border: 2px solid var(--secondary);
				border-radius: 5px;
				box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
				color: map-get($theme-colors, "blueish-80");
			}

			&::placeholder {
				color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
			}
		}

		.neo-input-icons {
			height: 100%;
			right: 6px;
			top: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
		}

		.neo-icons-required {
			right: 10px;
			top: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			> button {
				margin: 0;
				padding: 0;
				border: none;
				background: transparent;
				> i {
					margin: 0;
					padding: 0;
					color: map-get($theme-colors, "danger");
				}
			}
		}

		.neo-icon-calendar {
			> button {
				border: none;
				cursor: pointer;
				background: transparent;
				> i {
					color: map-get($theme-colors, "blueish-80");
				}
			}
		}
	}

	.neo-form-date {
		display: flex;
		flex-direction: row;

		.neo-input-valid {
			width: 100%;
			height: 40px;
			padding-left: 10px;
			border: 2px solid map-get($theme-colors, "grey-20");
			border-radius: 5px;
			color: map-get($theme-colors, "blueish-80");

			&:focus,
			&:focus-within,
			&:active {
				border: 2px solid var(--secondary);
				border-radius: 5px;
				box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.25);
				color: map-get($theme-colors, "blueish-80");
			}
			&::placeholder {
				color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
			}
		}

		.neo-input-invalid {
			width: 100%;
			height: 40px;
			padding-left: 10px;
			border: 2px solid map-get($theme-colors, "danger");
			border-radius: 5px;
			color: map-get($theme-colors, "blueish-80");

			&:focus,
			&:focus-within,
			&:active {
				border: 2px solid map-get($theme-colors, "danger");
				border-radius: 5px;
				box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "danger"), 0.25);
				color: map-get($theme-colors, "blueish-80");
			}

			&::placeholder {
				color: rgba(map-get($theme-colors, "blueish-80"), 0.4);
			}
		}

		.neo-icons-date {
			height: 100%;
			right: 6px;
			top: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			> button {
				border: none;
				background: transparent;
				cursor: pointer;
				> i {
					color: map-get($theme-colors, "blueish-20");
				}
			}
		}

		.neo-icons-required {
			right: 38px;
			top: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			> button {
				margin: 0;
				padding: 0;
				border: none;
				background: transparent;
				> i {
					margin: 0;
					padding: 0;
					color: map-get($theme-colors, "danger");
				}
			}
		}
	}

	.neo-from-calendar {
		position: absolute;
		z-index: 1000;
		right: 0;

		.drop-calendar {
			display: none;
			height: 100%;
			width: 390px;
			right: 0;
			border-radius: 5px;
			padding: 10px 15px 10px 15px;
			background: map-get($theme-colors, "white");
		}
	}
}

.box-error-message {
	width: 100%;
	border-radius: 5px;
	padding: 5px 0;
	border: 2px solid map-get($theme-colors, "danger");
	background: map-get($theme-colors, "red-10");
	color: map-get($theme-colors, "danger");

	&:focus-within {
		border: 2px solid map-get($theme-colors, "danger");
		border-radius: 5px;
		box-shadow: 0 0 0 0.2rem rgba(map-get($theme-colors, "danger"), 0.25);
		color: map-get($theme-colors, "danger");
	}

	.btn-error-message {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		background: transparent;
		border: none;
		box-shadow: none;
		outline: none;
		&:focus,
		&:active {
			border: none;
			box-shadow: none;
			outline: none;
		}
	}

	.expand-error-message {
		padding: 5px 10px;
		display: none;
	}
}

.message-validate {
	font-style: italic;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	margin: 3px 0 0 5px;

	&.danger {
		color: map-get($theme-colors, "danger");
	}

	&.dark {
		color: map-get($theme-colors, "blueish-50");
	}
}
