.badge {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.5rem;
	padding: 10px;
	font-weight: normal;
	border-radius: 15px;

	&.stadium {
		border-radius: 3px;
	}
}

@include badge("primary", false);
@include badge("secondary", false);
@include badge("success", false);
@include badge("danger", false);
@include badge("warning", false);
@include badge("info", false);
@include badge("light", true);
@include badge("blueish-80", false);
@include badge("grey-60", false);
