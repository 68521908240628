.text-primary {
	color: var(--primary) !important;
}
.text-secondary {
	color: var(--secondary) !important;
}

$colors: "success", "danger", "info", "blueish-50", "blueish-80", "grey-60",
	"grey-70", "grey-80";

@each $color in $colors {
	.text-#{$color} {
		color: map-get($theme-colors, $color) !important;
	}
}
.text-danger {
	color: map-get($theme-colors, "danger") !important;
}
