.welcome{
  text-align: center;
  height: 100ch;
  overflow: hidden;
  p{
    width: 69%;
    margin: auto;
    margin-top: 30px;
    font-size: 18px;
  }
}
.lori > img{
  width: 250px;
}


.lori.confirm > img{
  width: 400px;
}