.react-calendar {
	border: none;
	//width: 380px;
	margin: 0 auto;
}

.calendar-toggle {
	&::after {
		border-top: 0;
		border-right: 0;
		border-bottom: 0;
		border-left: 0;
	}
}

.datepicker {
	cursor: default;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	padding: 1em 0 0 0;
	margin: 0 auto;
	&.single-datepicker {
		width: 400px;
		margin: 0 auto;
	}

	&.mult-datepicker {
		width: 590px;
	}
	&-options {
		height: auto;
		max-width: 180px;
		border-right: 1px solid map-get($theme-colors, "light");
		font-size: 0.9em;
		&-title {
			padding: 0.1rem 1.25rem;
			font-weight: bold;
			line-height: 1em;
			color: map-get($theme-colors, "blueish-20");
		}
		&-item {
			cursor: pointer;
			padding: 0.3rem 1.25rem;
			height: 32px;
			border: none;
			border-radius: 0;
			&:last-child {
				border-bottom-right-radius: 0;
				border-bottom-left-radius: 0;
			}
			&:first-child {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
	&-header-text {
		margin-top: 10px;
		text-align: center;
		display: flex;
		color: map-get($theme-colors, "blueish-20");
		&-from {
			border-radius: 5px 0 0 5px;
			flex: 1;
			border: 1px solid map-get($theme-colors, "light");
			padding: 5px 10px;
			text-align: start;
			align-items: center;
			cursor: default !important;
		}
		&-to {
			border-radius: 0 5px 5px 0;
			flex: 1;
			border: 1px solid map-get($theme-colors, "light");
			padding: 5px 10px;
			text-align: start;
			cursor: default !important;
		}
	}
	&-calendar {
		flex: 1;
		width: 100%;
		padding: 1em;
		&-view {
			display: flex;
			flex: 0 1 100%;
			position: relative;
			.react-calendar__navigation {
				display: flex;
				align-items: center;
				&__prev-button {
					display: flex;
					align-items: center;
					visibility: hidden;
					&::after {
						visibility: visible;
						display: block;
						position: absolute;
						font-family: "Material Icons";
						content: "keyboard_arrow_left";
						color: map-get($theme-colors, "blueish-80");
						font-size: 24px;
						padding-top: 2.5px;
					}
				}
				&__next-button {
					display: flex;
					align-items: center;
					visibility: hidden;
					&::before {
						visibility: visible;
						display: block;
						position: absolute;
						font-family: "Material Icons";
						content: "keyboard_arrow_right";
						color: map-get($theme-colors, "blueish-80");
						font-size: 24px;
						padding-top: 2.5px;
						right: 6px;
					}
				}
			}
			&.isPerPeriod {
				.react-calendar__navigation__label {
					&:not(:disabled) {
						&:hover {
							cursor: pointer;
						}
						display: flex;
						align-items: center;
						justify-content: center;
						height: 24px;
						border-radius: 4px;
						background-color: map-get($theme-colors, "grey-10");
						span {
							height: 19px;
							font-weight: bold;
							font-size: 14px;
							line-height: 19px;
							text-decoration-line: underline;
							text-decoration-thickness: 0.5px;
							color: map-get($theme-colors, "blueish-80");
							&::first-letter {
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}
	&-footer {
		display: flex;
		flex: 0 1 100%;
		padding: 30px 10px 0 10px;
		justify-content: flex-end;
		&-confirm {
			&:disabled {
				color: map-get($theme-colors, "blueish-80");
				background-color: map-get($theme-colors, "grey-50");
				&:hover {
					color: map-get($theme-colors, "blueish-80") !important;
					background-color: map-get($theme-colors, "grey-50") !important;
				}
			}
		}
	}
}

.react-calendar__month-view__weekdays {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	margin: 0 !important;
	padding: 0 !important;
	color: map-get($theme-colors, "grey-65");
}

.react-calendar__month-view__weekdays__weekday {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	margin: 0 !important;
	padding: 0 !important;
}

.react-calendar__month-view__days {
	margin: 0 !important;
	padding: 0 !important;
}

.react-calendar__tile--active {
	background-color: map-get($theme-colors, "grey-60") !important;
	color: map-get($theme-colors, "white") !important;
	border-radius: 5px;
}

.react-calendar__tile.react-calendar__month-view__days__day {
	cursor: pointer;
	height: 35px;
	max-width: 13% !important;
	padding: 0;
	margin: 2.3px !important;
}

.react-calendar__tile--rangeStart {
	background-color: map-get($theme-colors, "grey-80") !important;
}

.react-calendar__tile--rangeEnd {
	background-color: map-get($theme-colors, "grey-80") !important;
}

.react-calendar__tile--hasActive {
	background-color: map-get($theme-colors, "grey-80") !important;
	color: map-get($theme-colors, "white") !important;
	border-radius: 5px;
}

.list-group-item.active {
	background-color: map-get($theme-colors, "grey-80");
}
.react-calendar__tile {
	background-color: map-get($theme-colors, "white");
	border: none;
}

.react-calendar__tile:focus {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
}

.react-calendar__tile--now {
	background: map-get($theme-colors, "white");
	color: map-get($theme-colors, "grey-90");
	font-weight: 700;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background-color: map-get($theme-colors, "grey-10") !important;
	color: black !important;
}

.react-calendar__navigation button[disabled] {
	background-color: map-get($theme-colors, "link");
}

.block-navigation {
	width: 380px;
	height: 200px;
	z-index: 100;
	position: fixed;
	bottom: 80px;
}

.react-calendar__tile.react-calendar__year-view__months__month {
	cursor: pointer;
	height: 80px;
}

.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
	background-color: map-get($theme-colors, "grey-80");
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
	background: map-get($theme-colors, "grey-80");
	color: map-get($theme-colors, "white");
	border-radius: 5px;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: map-get($theme-colors, "grey-80");
	color: map-get($theme-colors, "white");
	border-radius: 5px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	border-radius: 5px;
}

.react-calendar__navigation__arrow {
	background-color: map-get($theme-colors, "white");
	border: none;
	width: 2em;
	height: 3em;
	cursor: pointer;
}

.react-calendar__navigation__arrow:focus {
	outline: thin dotted;
	outline: 0px auto -webkit-focus-ring-color;
	outline-offset: 0px;
}

.react-calendar__navigation__label {
	border: none;
	height: 3em;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
	display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
	display: none;
}

.react-calendar__navigation__label {
	font-weight: bold;
	font-size: 0.9em;
	color: map-get($theme-colors, "grey-65");
	width: 20px;
}

.react-calendar__navigation__label::first-letter {
	text-transform: capitalize;
}

.react-calendar__month-view__weekdays__weekday {
	text-decoration: none;
	color: map-get($theme-colors, "grey-65");
	text-align: center;
}

abbr[title] {
	border-bottom: none !important;
	cursor: inherit !important;
	text-decoration: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover {
	background-color: map-get($theme-colors, "white");
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__tile--hover.react-calendar__tile--hoverEnd {
	background-color: map-get($theme-colors, "grey-10");
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: map-get($theme-colors, "grey-10");
}

.react-calendar__tile.react-calendar__year-view__months__month {
	cursor: pointer;
	height: 55px;
}
