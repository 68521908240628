@import "../../style.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

$toast-status: (
	"success": (
		"mainColor": map-get($theme-colors, "success"),
		"bgColor": map-get($theme-colors, "green-10"),
	),
	"error": (
		"mainColor": map-get($theme-colors, "danger"),
		"bgColor": map-get($theme-colors, "red-05"),
	),
	"info": (
		"mainColor": map-get($theme-colors, "info"),
		"bgColor": map-get($theme-colors, "blue-10"),
	),
	"warning": (
		"mainColor": map-get($theme-colors, "warning"),
		"bgColor": map-get($theme-colors, "orange-10"),
	),
);

.Toastify__toast-container {
	width: 400px !important;
}
.new-notification-toast {
	margin: 0 !important;
	padding: 0 !important;
	min-height: 56px !important;
	border-radius: 4px !important;

	@each $status, $pairValue in $toast-status {
		&.#{$status}-toast {
			background-color: map-get($pairValue, "bgColor") !important;
			.new-notification-toast-body {
				margin: 0 !important;
				display: flex;
				align-items: stretch;
				.toast {
					&-status-bar {
						width: 16px;
						background-color: map-get($pairValue, "mainColor");
					}
					&-icon {
						color: map-get($pairValue, "mainColor");
						font-size: 24px;
					}
					&-title {
						font-family: "Open Sans", sans-serif !important;
						font-weight: 600;
						font-size: 14px;
						line-height: 22px;
						color: map-get($theme-colors, "grey-90");
					}
					&-message {
						font-family: "Open Sans", sans-serif !important;
						font-style: normal;
						font-weight: 400;
						font-size: 13px;
						line-height: 18px;
						color: map-get($theme-colors, "grey-90");
					}
				}
			}
			.new-notification-toast-closeBtn {
				color: map-get($pairValue, "mainColor");
				font-size: 24px;
			}
		}
	}
}
