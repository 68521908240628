.categories-list {
	max-height: 400px;
	overflow-x: auto;
	scrollbar-color: #999 map-get($theme-colors, "bgNeo");
	scrollbar-width: thin;
	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background-color: map-get($theme-colors, "bgNeo");
	}

	&::-webkit-scrollbar {
		width: 6px;
		background-color: #999;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #999;
	}
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
	left: 52%;
	background-color: transparent;
	transform: translate(-50%, -50%) scale(1);
	transition: none;

	content: "indeterminate_check_box";
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 22px;
	border: 0;
	// margin: 0 0.55rem;
	color: #1890ff;
}

.editValue-parent-checkbox {
	.ant-tree-checkbox .ant-tree-checkbox-inner::before {
		position: absolute;
		left: 53%;
		top: 50%;
		background-color: transparent;
		transform: translate(-50%, -50%) scale(1);
		transition: none;

		content: "indeterminate_check_box";
		font-family: "Material Icons";
		font-weight: normal;
		font-style: normal;
		font-size: 22px;
		border: 0;
		// margin: 0 0.55rem;
		color: #1890ff;
	}
}
