@import "../../../../assets/css/style.scss";
.HW_badge {
	position: absolute !important;
	top: 0px !important;
	left: 15px !important;
}

#HW_frame_cont {
	max-height: 274px !important;
	left: 1049.4px;
	top: 94.0833px;
}

.HW_frame {
	max-height: 100% !important;
}

.HW_frame .ltr .outercont .count .innercont #index .logList {
	overflow-y: auto;
	height: 100% !important;
}

.outercont .innercont #index .logList {
	overflow-x: hidden;
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
	height: 100% !important;
}

.outercont .innercont .logList {
	overflow-y: auto;
	height: 100% !important;
}

.category_100780 {
	background-color: red !important;
}

.HW_badge[data-count-unseen=""] {
	background-color: map-get($theme-colors, "grey-50") !important;
}

.HW_badge {
	background-color: map-get($theme-colors, "success") !important;
}

.settings-dropdown-icon.active-widget::before {
	display: block;
	content: url("../../../../assets/images/dropdown-triangle.svg");
	position: absolute !important;
	top: 23px;
	z-index: 10;
	left: 50%;
	transform: translateX(-50%) scale(0.4);
}
.HW_frame_cont {
	position: relative;
	z-index: 0 !important;
	overflow: unset !important;
	margin-top: 10px !important;
}
