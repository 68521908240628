.app-store {
	width: 100%;
	height: 100ch;

	.card-store {
		background-color: map-get($theme-colors, "white");
		border: 1px solid map-get($theme-colors, "grey-20");
		margin: auto;
		margin-bottom: 15px;
		padding: 15px;
		font-size: 14px;
		width: 530px;
		color: #434343;
		border-radius: 5px;
		a {
			color: map-get($theme-colors, "info");
		}
		h4 {
			font-size: 16px;
			font-weight: 700;
		}
		p {
			margin-bottom: 0;
		}
	}
}

.number-store {
	font-size: 30px;
	margin: auto;
	padding: auto;
	padding-left: 35px;
	font-weight: 800;
	text-align: center;
}
.email-app {
	font-weight: 700;
}
