/* area do historico */
@import "../../assets/css/style.scss";

$timeline-status: (
	pending: (
		OrderType: map-get($theme-colors, "grey-65"),
		IntegrationType: map-get($theme-colors, "grey-60"),
	),
	completed: (
		OrderType: map-get($theme-colors, "success"),
		IntegrationType: map-get($theme-colors, "green-60"),
	),
	errorWarning: (
		OrderType: map-get($theme-colors, "warning"),
		IntegrationType: map-get($theme-colors, "warning"),
	),
	canceled: (
		OrderType: map-get($theme-colors, "danger"),
		IntegrationType: map-get($theme-colors, "danger"),
	),
	cancel-integration: (
		OrderType: map-get($theme-colors, "danger"),
		IntegrationType: map-get($theme-colors, "danger"),
	),
	processing: (
		OrderType: map-get($theme-colors, "info"),
		IntegrationType: map-get($theme-colors, "info"),
	),
	retry: (
		OrderType: map-get($theme-colors, "info"),
		IntegrationType: map-get($theme-colors, "info"),
	),
);

@mixin timeline-step($type, $size) {
	@each $status, $pairValue in $timeline-status {
		&.#{$status} {
			border-color: map-get($pairValue, "OrderType");
			border-style: solid;
			@if $type == "OrderType" {
				border-width: 3px;
				float: left;
			}
			@if $type == "IntegrationType" {
				border-width: 1px;
				float: right;
			}

			.main-icon {
				color: map-get($pairValue, "OrderType");
			}

			.timeline-step-mark {
				left: 50%;
				transform: translate(-50%);
				height: $size;
				width: $size;
				background-color: map-get($pairValue, $type);
				color: map-get($theme-colors, "white");

				@if $type == "IntegrationType" {
					@if #{$status} == "completed" or #{$status} == "cancel-integration" {
						border: 2px solid map-get($theme-colors, "white");
					} @else {
						background-color: map-get($theme-colors, "white");
						border: 2px solid map-get($pairValue, $type);
					}
				}
			}
			@if #{$status} == "pending" {
				.main-icon,
				.order-history-step-title,
				.order-history-step-text {
					color: map-get($theme-colors, "grey-60") !important;
				}
				border: 1px dashed map-get($theme-colors, "grey-50") !important;
				// height: fit-content;
				height: 52px;
			}
		}
	}
}

.history-card-body {
	overflow-x: auto;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		height: 8px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track {
		border-radius: 40px;
		background-color: map-get($theme-colors, "grey-20");
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 40px;
		background-color: map-get($theme-colors, "grey-50");
	}
}

.order-history {
	width: 872px;
	position: relative;

	.progress-bar-vertical {
		// width: 20px;
		width: 2.5px;

		position: absolute;
		background-color: map-get($theme-colors, "grey-65");

		.progress-bar {
			width: 100%;
			-webkit-transition: height 0.6s ease;
			-o-transition: height 0.6s ease;
			transition: height 0.6s ease;

			@each $status, $pairValue in $timeline-status {
				&.#{$status} {
					background-color: map-get($pairValue, "OrderType");
				}
			}
		}
	}

	.order-history-step {
		line-height: 0;
		padding: 13px;
		color: map-get($theme-colors, "blueish-80");
		margin-bottom: 20px;
		width: 400px;
		height: 75px;
		border-radius: 4px;
		clear: both; // Para respeitar o local do float anterior

		&.OrderType {
			@include timeline-step("OrderType", 40px);
		}

		&.IntegrationType {
			@include timeline-step("IntegrationType", 16px);
		}

		&:last-child {
			margin-bottom: 0px !important;
		}

		.order-history-step-title {
			font-weight: 700;
			font-size: 14px;
			line-height: 19px;
		}
		.order-history-step-text {
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
		}
		.downloadLog {
			color: map-get($theme-colors, "grey-50");
			cursor: pointer;
		}
	}
}
