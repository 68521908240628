.preview {
	&.app {
		background: map-get($theme-colors, "grey-20");
		box-shadow: 0 0 0 5px map-get($theme-colors, "grey-20");
		border: 1px solid darken(map-get($theme-colors, "grey-20"), 5%);
		border-radius: 35px;
		padding: 7px;
		overflow: hidden;
		width: 266px;
		.body {
			width: 250px;
			height: 520px;
			background: map-get($theme-colors, "white");
			border-radius: 25px;
			overflow: hidden;
			overflow-y: auto;
			position: relative;
			&::-webkit-scrollbar-track {
				// -webkit-box-shadow: inset 0 0 6px $secondary-color;
				border-radius: 10px;
				background-color: map-get($theme-colors, "bgNeo");
			}
		
			&::-webkit-scrollbar {
				display: none;
				width: 6px;
				background-color: #999;
			}
		
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				// -webkit-box-shadow: inset 0 0 6px $secondary-color;
				background-color: #999;
			}
		}
		.app-bar {
			padding-top: 25px;
			background-color: map-get($theme-colors, "grey-20");
			height: 65px;
			display: flex;
			align-items: center;
			position: absolute;
			top: 0;
			width: 100%;

			[class^='action-'] {
				flex: 1;
				display: flex;
				color: map-get($theme-colors, "white");
			}
			.action-left {
				padding-left: 10px;
				justify-content: flex-start;
			}
			.action-center {
				justify-content: center;
			}
			.action-right {
				justify-content: flex-end;
				padding-right: 10px;
			}
		}
	}
	.bottom-navigation {
		position: absolute;
		bottom: 0;
		width: 100%;
		background: map-get($theme-colors, "white");
		display: flex;
		align-items: center;
		height: 45px;
		padding-bottom: 15px;
		padding-top: 5px;
		border-top: 1px solid map-get($theme-colors, "grey-20");

		.action-item {
			flex: 1;
			color:map-get($theme-colors, "grey-65");
			text-align: center;
			.material-icons {
				font-size: 20px;
				margin: 0;
				display: block;
				line-height: 18px;
			}
			.label {
				display: block;
				font-size: 8px;
			}
		}
	}
	.app-content {
		position: absolute;
		top: 65px;
		bottom: 45px;
		width: 100%;
		overflow-y: auto;
		&::-webkit-scrollbar-track {
			// -webkit-box-shadow: inset 0 0 6px $secondary-color;
			border-radius: 10px;
			background-color: map-get($theme-colors, "bgNeo");
		}

		&::-webkit-scrollbar {
			width: 0px;
			background-color: map-get($theme-colors, "bgNeo");
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			// -webkit-box-shadow: inset 0 0 6px $secondary-color;
			background-color: #999;
		}
	}
	.app-carousel-banner {
		height: 160px;
		background: map-get($theme-colors, "grey-20");
		position: relative;
		.carousel-inner {
			height: 100%;
			.carousel-item {
				height: 100%;
				.banner {
					// width:100%;
					height: 100%;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					display: flex;
					flex: 1;
					color: #999;
				}
			}
		}
	}
	.app-list-banners {
		padding: 10px;
		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			li {
				margin-bottom: 10px;

				.banner {
					&-body {
                        background-size: cover;
                        background-repeat: no-repeat;
						height: 140px;
						background-position: center;
						border-radius: 5px;
						box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16),
							0 1px 4px rgba(0, 0, 0, 0.15);
                        background-color: #dee2e6;
                        
					}
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.tutorial-title {
	color: map-get($theme-colors, "white");
	text-align: center;
	padding-top: 21px;
	line-height: 16px;
	width: 152px;
	margin: auto;
	font-size: 14px;
	span {
		font-size: 35px;
		margin-bottom: 20px;
	}
}
.tutorial-content {
	font-size: 12px;
	line-height: 14px;
	text-align: center;
	padding: 19px;
}

//css for list banners
.list-banner {
  display: flex;
  .banner-img {
    >img {
      border-radius: 2px;
      margin: 5px;
      width:70px;
      height: 70px;
    }
  }
  .banner-description {
    margin: 5px;
    width: 340px;
    >label, h6 {
      margin: 0;
      padding: 0;
    }
  }
  .banner-label {
    display: flex;
    align-items: center;
    margin: 5px;
    width: 200px;
    padding: 0 10px; 
  }
  .banner-buttons {
    display: flex;
    align-items: center;
    margin: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .list-banner {
    display: flex;
    .banner-description {
      width: 200px;
    }
    .banner-label {
      align-items: center;
      width: 100px; 
    }
  }
}

@media only screen and (max-width: 996px) {
  .list-banner {
    display: flex;
    flex-direction: column;
    .banner-description {
      width: 100%;
    }
    .banner-label {
      margin: 0;
      padding-left: 5px; 
    }
  }
}
