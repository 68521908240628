.toast {
	display: flex;
	
	height: 40px;
	align-items: center;
  justify-content: space-between;
	
	box-sizing: border-box;
	border-radius: 5px;
	margin: 1em 0;
	padding: 10px;
	color: map-get($theme-colors, 'white');
	&-content {
    display: flex;
		align-items: center;
	}
  &-close{
    display: flex;
		align-items: center;
    color: map-get($theme-colors, 'white');
    text-decoration:none;
    &:hover{
      color: map-get($theme-colors, 'white');
      text-decoration:none;
    }
    &:active{
      color: map-get($theme-colors, 'white');
      text-decoration:none;
    }
    &:focus{
      color: map-get($theme-colors, 'white');
      text-decoration:none;
    }
  }
}

.successes{
  border: 2px solid map-get($theme-colors, 'success');
  background: map-get($theme-colors, 'success');
}

.error{
  border: 2px solid map-get($theme-colors, 'danger');
  background: map-get($theme-colors, 'danger');
}
