@mixin card-default {
	background: map-get($theme-colors, "white");
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
	border-radius: 5px;
}
// Button Mixin
@mixin button($name, $dark-text) {
	.btn-#{$name} {
		// Initial style
		background-color: map-get($theme-colors, #{$name});
		border: 0;
		border-radius: 5px;
		cursor: pointer;
		@if $dark-text {
			color: map-get($theme-colors, "blueish-80");
		} @else {
			color: map-get($theme-colors, "white");
		}

		// Icon Style
		&.btn-icon-c-r,
		&.btn-icon-c-l {
			i {
				background: darken(map-get($theme-colors, #{$name}), 10%);
			}
		}
		//Initial Hover style
		&:hover,
		&:focus,
		&:active {
			outline: 0;
			box-shadow: none;
			background-color: darken(map-get($theme-colors, #{$name}), 5%) !important;
			@if $dark-text {
				color: map-get($theme-colors, "blueish-80");
			} @else {
				color: map-get($theme-colors, "white");
			}
		}

		// Outline Style
		&.outline {
			border: 1px solid map-get($theme-colors, #{$name});
			background: map-get($theme-colors, "link");
			@if $dark-text {
				color: map-get($theme-colors, "blueish-80");
			} @else {
				color: map-get($theme-colors, #{$name});
			}

			&:hover {
				background: map-get($theme-colors, #{$name});
				@if $dark-text {
					color: map-get($theme-colors, "blueish-80");
				} @else {
					color: map-get($theme-colors, "white");
				}
			}
		}

		// Glow Style
		&.glow {
			box-shadow: 0 2px 4px 0 rgba(map-get($theme-colors, #{$name}), 0.5) !important;
			&:hover {
				box-shadow: 0 4px 12px 0 rgba(map-get($theme-colors, #{$name}), 0.6) !important;
			}
		}

		//Light Style
		&.light {
			background-color: rgba(map-get($theme-colors, #{$name}), 0.2);
			color: map-get($theme-colors, #{$name});

			&.outline {
				border-color: rgba(map-get($theme-colors, #{$name}), 0.2);
				background: map-get($theme-colors, "link");
				color: map-get($theme-colors, #{$name});

				&:hover {
					color: map-get($theme-colors, "white");
					background-color: rgba(
						map-get($theme-colors, #{$name}),
						0.2
					) !important;
					border-color: map-get($theme-colors, "link");
				}
			}

			// Icon Style
			&.btn-icon-c-r,
			&.btn-icon-c-l {
				i {
					background-color: rgba(
						map-get($theme-colors, #{$name}),
						0.3
					) !important;
				}
			}

			&:hover,
			&:focus,
			&:active {
				background-color: rgba(
					map-get($theme-colors, #{$name}),
					0.3
				) !important;
				color: map-get($theme-colors, #{$name}) !important;

				&.btn-icon-c-r,
				&.btn-icon-c-l {
					i {
						background-color: rgba(
							map-get($theme-colors, #{$name}),
							0.4
						) !important;
					}
				}
			}
		}
	}
}
@mixin badge($name, $dark-text) {
	.badge-#{$name} {
		@if #{$name} == "primary" {
			background-color: var(--primary);
		} @else if #{$name} == "secondary" {
			background-color: var(--secondary);
		} @else {
			background-color: map-get($theme-colors, #{$name});
		}
		@if $dark-text {
			color: map-get($theme-colors, "blueish-80");
		} @else {
			color: map-get($theme-colors, "white");
		}
	}
}
.m-icon {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-feature-settings: "liga";
	-webkit-font-smoothing: antialiased;
}
