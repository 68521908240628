.tracking-card {
	height: 100%;

	.btn:disabled {
		background-color: map-get($theme-colors, "grey-50") !important;
		color: map-get($theme-colors, "white");
	}

	.create-tracking {
		height: 48px;
		background-color: transparent;
		border-radius: 4px;
		border: solid 1px var(--primary);
		color: var(--primary);
		cursor: pointer;
	}
	.create-tracking:focus {
		box-shadow: 0 0 0 0;
		outline: 0;
	}
	.create-tracking:hover {
		background-color: var(--primary);
		border: solid 1px var(--primary);
		color: map-get($theme-colors, "white");
	}

	.tracking-box {
		display: flex;
		flex-direction: row;
		a {
			color: map-get($theme-colors, "info");
			font-size: 14px;
			font-weight: 500;
			font-style: italic;
			margin-left: 8px;
		}
		input {
			background: map-get($theme-colors, "red");
			border: none;
			font-size: 14px;
			position: absolute;
			top: 900px;
			color: red;
		}

		button {
			border: none;
			background: transparent;
			cursor: pointer;

			i {
				color: map-get($theme-colors, "grey-60");
				font-size: 18px;
				font-weight: 500;
			}
		}

		button:focus {
			box-shadow: 0 0 0 0;
			outline: 0;
		}

		input:focus {
			box-shadow: 0 0 0 0;
			outline: 0;
		}
	}
}

$steplist: (
	firstStep: (
		1: (
			circleTextColor: map-get($theme-colors, "success"),
			labelColor: map-get($theme-colors, "success"),
			background: map-get($theme-colors, "white"),
		),
		2: (
			circleTextColor: map-get($theme-colors, "grey-60"),
			labelColor: map-get($theme-colors, "grey-60"),
			background: map-get($theme-colors, "white"),
		),
	),
	secondStep: (
		1: (
			circleTextColor: map-get($theme-colors, "white"),
			labelColor: map-get($theme-colors, "success"),
			background: map-get($theme-colors, "success"),
		),
		2: (
			circleTextColor: map-get($theme-colors, "success"),
			labelColor: map-get($theme-colors, "success"),
			background: map-get($theme-colors, "white"),
		),
	),
);

@mixin steplist() {
	@each $step, $pairValue in $steplist {
		&.#{$step} {
			@each $stepNumber, $stepValues in $pairValue {
				.steplist-step:nth-of-type(#{$stepNumber}) {
					margin: 0 56px;
					width: 130px;

					.steplist-step-circle {
						height: 48px;
						width: 48px;
						z-index: 1;
						border: 3px solid map-get($stepValues, "labelColor");
						background: map-get($stepValues, "background");
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
						text-align: center;
						color: map-get($stepValues, "circleTextColor");
						.material-icons {
							height: 24px;
							width: 24px;
						}
					}
					.steplist-step-label {
						font-style: normal;
						font-weight: 700;
						font-size: 12px;
						line-height: 16px;
						text-align: center;
						color: map-get($stepValues, "labelColor");
					}

					&:first-of-type {
						&::before {
							content: "";
							display: block;
							position: absolute;
							z-index: 0;
							top: 24px;
							left: 142px;
							width: 256px;
							height: 3px;
							@if $step == "firstStep" {
								background: map-get($theme-colors, "grey-60");
							} @else {
								background: map-get($theme-colors, "success");
							}
						}
					}
				}
			}
		}
	}
}

.tracking-edit-link {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	text-decoration-line: underline !important;
	color: map-get($theme-colors, "info") !important;
}

.tracking-modal {
	width: 588px;
	&-header {
		h5 {
			font-style: normal !important;
			font-weight: 700 !important;
			font-size: 24px !important;
			line-height: 33px !important;
			color: map-get($theme-colors, "blueish-80") !important;
		}
		.steplist {
			@include steplist();
		}
	}
	&-body {
		.tracking-form-title {
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			color: map-get($theme-colors, "blueish-80") !important;
		}
		.tracking-input-label {
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 16px;
			color: map-get($theme-colors, "blueish-80") !important;
		}
		.tracking-modal-footer {
			button {
				width: 190px;
				&.cancel-button {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					text-align: center;
					text-decoration-line: underline;
					color: map-get($theme-colors, "blueish-50");
				}
				&.confirm-button {
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					text-align: center;
					color: map-get($theme-colors, "white");
				}
			}
		}
	}
}
