.assistant-shipping {
	margin: 0;
	padding: 0;

	.timeline {
		color: map-get($theme-colors, "grey-50");
		height: 50px;
		display: flex;

		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			display: flex;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			align-self: center;
			align-content: center;
			overflow: hidden;
			text-transform: uppercase;
			&::before {
				width: 70%;
				height: 3px;
				background: map-get($theme-colors, "grey-50");
				content: "";
				display: block;
				position: absolute;
				top: 105px;
			}

			li {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				align-content: center;
				align-self: center;
				transition: 0.4s;

				button {
					width: 25px;
					height: 25px;
					border-radius: 50%;
					background-color: map-get($theme-colors, "white");
					border: 3px solid map-get($theme-colors, "grey-50");
					display: flex;
					align-items: center;
					cursor: pointer;
					z-index: 1;
					outline: 0 !important;

					&.checked {
						background-color: map-get($theme-colors, "success");
						border-color: map-get($theme-colors, "success");
					}
				}

				button:focus {
					outline: 0px auto -webkit-focus-ring-color;
					outline-offset: 0px;
				}

				span {
					margin-top: 10px;
					display: flex;
					justify-content: center;
				}
				&.completed {
					&::before {
						border: 5px solid map-get($theme-colors, "success");
						background-color: map-get($theme-colors, "success");
					}
					&::after {
						width: 100%;
						height: 3px;
						position: relative;
						left: 120px;
						bottom: 43px;
						background-color: map-get($theme-colors, "success");
						content: "";
					}
					span {
						color: map-get($theme-colors, "success");
						font-weight: bold;
					}
				}
			}
		}
	}
}

@media (max-width: 990px) {
	.assistant-shipping {
		.timeline {
			ul {
				&::before {
					top: 103px; // aqui
				}
				li {
					span {
						font-size: 10px;
						text-align: center;
						width: 80px;
						height: 40px;
						display: flex;
						justify-content: center;
					}
					&.completed {
						&::after {
							width: 100%;
							height: 3px;
							position: relative;
							left: 67px;
							bottom: 62px;
							background: map-get($theme-colors, "success");
							content: "";
						}
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.assistant-shipping {
		.timeline {
			ul {
				&::before {
					top: 103px; // aqui
				}
				li {
					span {
						font-size: 10px;
						text-align: center;
						width: 80px;
						height: 40px;
						display: flex;
						justify-content: center;
					}
					&.completed {
						&::after {
							width: 100%;
							height: 3px;
							position: relative;
							left: 50px;
							bottom: 62px;
							background: map-get($theme-colors, "success");
							content: "";
						}
					}
				}
			}
		}
	}
}
